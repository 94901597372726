import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import RandomQuoteSaveForm from "../components/RandomQuoteSaveForm";

const RandomQuoteSave = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" hbtnClass="d-"/>
            <RandomQuoteSaveForm/>
        </div>
    )
}
export default RandomQuoteSave;