import {Alert, Spinner} from "@patternfly/react-core";
import React, {useState} from "react";
import {addPublisher, saveRandomQuote} from "./api/apiCalls";

const PublisherSaveForm = () => {
    const [nameError, setNameError] = useState("");

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const [name, setName] = useState("");

    const onSubmit = async event => {
        setNameError(undefined)
        if (name === undefined || name === "" || name.trim() === "") {
            setNameError("İsim boş olamaz")
            return
        }
        event.preventDefault();
        setSending(true);
        const body = {
            "name": name,
        }
        try {
            let response = await addPublisher(body);
            if (response.status === 200) {
                setSuccess(true);
                await new Promise(r => setTimeout(r, 1500));
                window.location.reload();
            } else {
                setNameError(response?.response?.data?.validationErrors);
                setNameError(response?.response?.data?.message);
            }
        } catch (error) {
            setSending(false);
            setNameError(error.response?.data?.validationErrors?.name);
        }
        setSending(false);
    };

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {success ? <Alert className="alert_sign" variant="success" timeout="3000"
                                      title="Yayınevi Kaydedildi"/> : ""}
                    <div className="sign_info">
                        <div className="row">
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Yayınevi Ekle</h2>
                                <form action="#" className="">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Yayınevi İsim*</label>
                                        <input
                                            required
                                            name="quote"
                                            onChange={(e) => setName(e.target.value)}
                                            type="text"/>
                                        <span style={{color: "red"}}> {nameError} </span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {!sending ? <button disabled={sending} type="button" onClick={onSubmit}
                                                            className="btn_three">Kaydet</button> : <Spinner/>}
                                    </div>
                                    <br/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PublisherSaveForm;