import axios from "axios";

// const base_url = "https://api.qupic.net";
const base_url = `${process.env.REACT_APP_API_URL}`
export const setAuthorizationHeader = ({isLoggedIn, token}) => {
    if (isLoggedIn) {
        axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers['Authorization'];
    }
};

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        localStorage.clear();
        window.location.replace("/")
        window.location.reload();
    }
    return error;
});

//DASHBOARD

export const getDashboard = () => {
    return axios.get(base_url + "/api/v1/admin/dashboard");
}

//REDIS
export const removeCache = () => {
    return axios.get(base_url + "/api/v1/admin/redis");
}
export const listCaches = () => {
    return axios.get(base_url + "/api/v1/admin/redis/list");
}

// LOGIN
export const login = creds => {
    return axios.post(base_url + '/api/v1/login/admin', creds);
};

// BOOK
export const searchBook = (text) => {
    return axios.get(base_url + '/api/v1/book/search?text=' + text);
}
export const getBookByID = (id) => {
    return axios.get(base_url + '/api/v1/admin/book/' + id);
}
export const getBookList = (params) => {
    return axios.get(base_url + '/api/v1/admin/book' + params);
}
export const saveBook = (body) => {
    return axios.post(base_url + '/api/v1/admin/book', body);
}
export const updateBook = (id, body) => {
    return axios.put(base_url + `/api/v1/admin/book/${id}`, body);
}
export const deleteBook = (id) => {
    return axios.post(base_url + '/api/v1/admin/book/delete/' + id);
}
export const publishBook = (id) => {
    return axios.post(base_url + `/api/v1/admin/book/${id}/publish`);
}
export const unpublishBook = (id) => {
    return axios.post(base_url + `/api/v1/admin/book/${id}/unPublish`);
}

// USER
export const getUserList = (params) => {
    return axios.get(base_url + "/api/v1/admin/user" + params);
}
export const getAdminUsers = () => {
    return axios.get(base_url + "/api/v1/admin/user/admin");
}
export const user = () => {
    return axios.get(base_url + '/api/v1/userProfile');
}
export const getAdminList = (params) => {
    return axios.get(base_url + '/api/v1/user/resetPassword', params);
}
export const changePass = (body) => {
    return axios.post(base_url + '/api/v1/userProfile/updatePassword', body)
}
export const changeRole = (id) => {
    return axios.post(base_url + `/api/v1/admin/user/${id}/changeRole`)
}

// AUTHOR
export const getOneAuthor = (id) => {
    return axios.get(base_url + `/api/v1/author/${id}`);
}
export const getAuthorList = (params) => {
    return axios.get(base_url + "/api/v1/admin/author" + params);
}
export const updateAuthor = (id, body) => {
    return axios.put(base_url + "/api/v1/admin/author/" + id, body);
}
export const saveAuthor = (body) => {
    return axios.post(base_url + '/api/v1/admin/author', body);
}

// QUOTE
export const getQuote = (params) => {
    return axios.get(base_url + `/api/v1/admin/quote${params}`);
}
export const saveQuote = (body) => {
    return axios.post(base_url + '/api/v1/admin/quote', body);
}
export const deleteQuote = (id) => {
    return axios.delete(base_url + '/api/v1/admin/quote/' + id);
}
//RANDOM QUOTE
export const getRandomQuote = (params) => {
    return axios.get(base_url + `/api/v1/admin/randomQuote${params}`);
}
export const saveRandomQuote = (body) => {
    return axios.post(base_url + '/api/v1/admin/randomQuote', body);
}
export const deleteRandomQuote = (id) => {
    return axios.post(base_url + `/api/v1/admin/randomQuote/${id}`);
}

// Publisher
export const getPublisherList = (params) => {
    return axios.get(base_url + "/api/v1/admin/publisher" + params);
}
export const addPublisher = (body) => {
    return axios.post(base_url + "/api/v1/admin/publisher", body);
}
export const deletePublisher = (id) => {
    return axios.delete(base_url + "/api/v1/admin/publisher/" + id);
}

// AWS S3
export const getImages = () => {
    return axios.get(base_url + "/api/v1/admin/file");
}
export const uploadImage = (formData) => {
    return axios.post(base_url + "/api/v1/admin/file/upload",
        formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
}

// VERSION
export const getVersions = () => {
    return axios.get(base_url + "/api/v1/admin/version")
}

export const updateVersion = (params) => {
    return axios.post(base_url + `/api/v1/admin/version?${params}`)
}

// COMMENT
export const getComments = (params) => {
    return axios.get(base_url + `/api/v1/admin/comment${params}`)
}
export const approveComment = (id) => {
    return axios.post(base_url + `/api/v1/admin/comment/${id}/approve`)
}
export const disapproveComment = (id) => {
    return axios.post(base_url + `/api/v1/admin/comment/${id}/disapprove`)
}
export const deleteComment = (id) => {
    return axios.delete(base_url + `/api/v1/admin/comment/${id}`)
}

// HOME ADMIN
export const getHomepageAdmin = () => {
    return axios.get(base_url + `/api/v1/admin/home`)
}
export const getHomepageRecommendedBook = () => {
    return axios.get(base_url + `/api/v1/home/recommendedBook`)
}
export const getHomepageRecommendedList = () => {
    return axios.get(base_url + `/api/v1/home/recommendedBookList?size=100`)
}
export const getHomepageEditorsChoice = () => {
    return axios.get(base_url + `/api/v1/home/editorsChoice?size=100`)
}
export const getHomepageTopBooks = () => {
    return axios.get(base_url + `/api/v1/home/topBooks`)
}
export const getHomepageLast20Books = () => {
    return axios.get(base_url + `/api/v1/home/lastBooks`)
}

export const deleteHomepageItemAdmin = (typeId, bookId) => {
    return axios.delete(base_url + `/api/v1/admin/home?type=${typeId}&bookId=${bookId}`)
}

export const saveHomepageItemAdmin = (body) => {
    return axios.post(base_url + `/api/v1/admin/home`, body)
}


// CATEGORY
export const getCategories = (page, size) => {
    return axios.get(`${base_url}/api/v1/admin/category?page=${page}&size=${size}`);
}
export const saveCategories = (body) => {
    return axios.post(`${base_url}/api/v1/admin/category`, body);
}
export const deleteCategory = (id) => {
    return axios.delete(`${base_url}/api/v1/admin/category/${id}`);
}