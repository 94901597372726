import {Alert, Button, Spinner} from '@patternfly/react-core';
import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import {changeRole, getUserList} from "./api/apiCalls";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const UserListForm = (props) => {

    const [key] = useState(0);
    const [mail, setMail] = useState("");
    const [loader] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [params, setParams] = useState();

    const [success, setSuccess] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [msg, setMsg] = useState();

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'Ad',
            selector: row => row.name ? row.name : "-",
        },
        {
            name: 'Soyad',
            selector: row => row.surname ? row.surname : "-",
        },
        {
            name: 'E-posta',
            selector: row => row.mail,
        },
        {
            name: 'Google / Facebook ID',
            selector: row => <span>{row.googleId ? row.googleId : "-"} / {row.facebookId ? row.facebookId : "-"}</span>,
        },
        {
            name: 'Role Değiştir',
            selector: row =>
                <Button
                    className={row.isAdmin ? "btn_get btn_hover pf-m-danger" : "btn_get btn_hover pf-m-primary"}
                    style={{minWidth: "80px"}}
                    onClick={() => {
                        const role = row.isAdmin ? "ROLE_USER" : "ROLE_ADMIN";
                        roleChange(row.id, role);
                    }}>
                    {row.isAdmin ? "Yönetici Sil" : "Yönetici Yap"}
                </Button>,
        }
    ];

    const roleChange = (id, role) => {
        setSuccess(false);
        setApiError(false);
        confirmAlert({
            title: 'Kullanı Rol Değiştir',
            message: `Kullanıcının rolü '${role}' değiştirilecektir onaylıyor musunuz?`,
            buttons: [
                {
                    label: 'Evet',
                    onClick: async () => {
                        await changeRole(id)
                            .then(r => {
                                if (r.status === 200) {
                                    setSuccess(true)
                                    setMsg("Kullanıcı rolü başarıyla güncellendi")
                                } else {
                                    setApiError(true);
                                    setMsg(r.response?.data?.message)
                                }
                                fetchUsers(0);
                            }).catch(error => {
                                setApiError(true);
                                setMsg("API erişim hatası / console log")
                                console.log(error)
                            })
                    }
                },
                {
                    label: 'Hayır',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    const fetchUsers = async page => {
        setLoading(true);

        if (params !== undefined) {
            const response = await getUserList("?" + params + `&page=${page}&size=${perPage}`);
            setData(response.data.data.content);
            setTotalRows(response.data.data.totalElements);
            setLoading(false);
        } else {
            const response = await getUserList(`?page=${page}&size=${perPage}&delay=1`);
            setData(response.data.data.content);
            setTotalRows(response.data.data.totalElements);
            setLoading(false);
        }
    };

    const handlePageChange = page => {

        fetchUsers(page - 1);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        page = page - 1
        const response = await getUserList(`?page=${page}&size=${newPerPage}`);

        setData(response.data.data.content);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const filter = async ({nameA, surnameA, mailA}) => {
        setLoading(true);
        const params = new URLSearchParams({
            name: nameA ? nameA : nameA === '' ? '' : firstName,
            surname: surnameA ? surnameA : surnameA === '' ? '' : lastName,
            mail: mailA ? mailA : mailA === '' ? '' : mail,
        }).toString();
        setParams(params);
        const data = await getUserList("?" + params);
        setData(data.data.data.content);
        setTotalRows(data.data.data.totalElements);
        setLoading(false);
    }

    const reset = async () => {
        setLoading(true);
        setFirstName("")
        setLastName("")
        setMail("")
        const params = new URLSearchParams({
            name: "",
            surname: "",
            mail: "",
        }).toString();
        setParams(params);
        const data = await getUserList("");
        setData(data.data.data.content);
        setTotalRows(data.data.data.totalElements);
        setLoading(false);
        return data
    }

    useEffect(() => {
        fetchUsers(0);
    }, [key]);

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {success ?
                        <Alert className="alert_sign popup-fixed" variant="success" timeout="3000" title={msg}/> : ""}
                    {apiError ?
                        <Alert className="alert_sign popup-fixed" variant="danger" timeout="10000" title={msg}/> : ""}
                    {loader ? <div className="sign_info">
                        <div className="row" style={{paddingBottom: "10px"}}>
                            <div className="col-lg-3 nopadding">
                                <input
                                    style={{width: "100%", height: "100%"}}
                                    placeholder="E-posta"
                                    value={mail}
                                    onChange={e => {
                                        const name = e.target.value.trim() || '';
                                        setMail(name)
                                        filter({mailA: name});
                                    }}
                                /></div>
                            <div className="col-lg-3 nopadding">
                                <input
                                    style={{width: "100%", height: "100%"}}
                                    placeholder="Ad"
                                    value={firstName}
                                    onChange={e => {
                                        const name = e.target.value.trim() || '';
                                        setFirstName(name)
                                        filter({nameA: name});
                                    }}
                                />
                            </div>
                            <div className="col-lg-3 nopadding">
                                <input
                                    style={{width: "100%", height: "100%"}}
                                    placeholder="Soyad"
                                    value={lastName}
                                    onChange={e => {
                                        const name = e.target.value.trim() || '';
                                        setLastName(name)
                                        filter({surnameA: name});
                                    }}
                                /></div>
                            <div className="col-lg-3" style={{display: "flex"}}>
                                <Button component="a" className="btns filter-item"
                                        onClick={e => filter({
                                            nameA: firstName,
                                            surnameA: lastName,
                                            mailA: mail
                                        })} target="_blank"
                                        style={{background: "#06c", color: "white"}}
                                        variant="tertiary">
                                    Filtrele
                                </Button>

                                <Button component="a" className="btns filter-item"
                                        onClick={e => reset()} target="_blank"
                                        style={{background: "#dc3545", color: "white"}}
                                        variant="tertiary">
                                    Sıfırla
                                </Button>
                            </div>
                        </div>
                        <DataTable
                            title="Kullanıcılar"
                            highlightOnHover={true}
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            striped
                            data-tag="allowRowEvents"
                            noDataComponent="Sonuç Bulunamadı"
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            progressComponent={<Spinner/>}
                        />
                    </div> : <><br/><br/>
                        <center><Spinner/></center>
                    </>}
                </div>
            </section>
        </>
    )
}
export default UserListForm;