import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import BookUpdateForm from "../components/BookUpdateForm";

const BookUpdate = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" hbtnClass="d-"/>
            <BookUpdateForm/>
        </div>
    )
}
export default BookUpdate;