import {Button, Spinner} from '@patternfly/react-core';
import React, {useEffect, useState} from "react";
import DataTable, {Alignment} from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import {deletePublisher, getPublisherList} from "./api/apiCalls";
import 'reactjs-popup/dist/index.css';
import {useHistory} from "react-router-dom";

const PublisherListForm = (props) => {
    const [key] = useState(0);
    const [loader] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [publisher, setPublisher] = useState("");
    const history = useHistory();

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'İsim',
            selector: row => row.name,
        },
        {
            name: 'Yayın Durumu',
            selector: row => row.active ? "Evet" : "Hayır",
        },
        {
            name: 'Sil',
            selector: row =>
                row.active ?
                    <Button className="btn_get btn_hover pf-m-danger" style={{minWidth: "80px"}} onClick={() => {
                        deletePublisher(row.id)
                        fetchPublishers(0)
                    }}>Sil</Button> :
                    <Button className="btn_get btn_hover pf-m-info" style={{minWidth: "80px"}} onClick={() => {
                        deletePublisher(row.id)
                        fetchPublishers(0)
                    }}>Yayınla</Button>
        },
    ];

    const fetchPublishers = async page => {
        setLoading(true);
        const response = await getPublisherList(`?&page=${page}&size=${perPage}`);
        setData(response?.data?.data?.content);
        setTotalRows(response?.data?.data?.totalElements);
        setLoading(false);
    };


    const filter = async ({publisherA}) => {
        const params = new URLSearchParams({
            name: publisherA ? publisherA : publisherA === '' ? publisherA : publisher,
            page: currentPage,
            size: perPage
        }).toString();
        setLoading(true);
        const response = await getPublisherList(`?${params}`);
        setData(response?.data?.data?.content);
        setTotalRows(response?.data?.data?.totalElements);
        setLoading(false);
    };

    const handlePageChange = page => {
        page = page <= 0 ? 0 : page - 1;
        setCurrentPage(page)
        fetchPublishers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        page = page <= 0 ? 0 : page - 1;
        setCurrentPage(page)
        setPerPage(newPerPage);

        const response = await getPublisherList(`?page=${page}&size=${newPerPage}`);
        setData(response?.data?.data?.content);
        setTotalRows(response?.data?.data?.totalElements);
        setLoading(false);
    };

    useEffect(() => {
        fetchPublishers(0);
    }, [key]);

    function getSubHeaderComponent() {
        return <Button onClick={e => history.push('/yayinevi-ekle')}>Yayınevi ekle</Button>;
    }

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {loader ?
                        <div className="sign_info">
                            <div className="row" style={{paddingBottom: "10px"}}>
                                <div className="col-lg-6 nopadding">
                                    <input
                                        placeholder="Yayınevi"
                                        value={publisher}
                                        style={{width: "100%", height: "100%"}}
                                        onChange={(e) => {
                                            const name = e.target.value.trim() || '';
                                            setPublisher(name);
                                            filter({publisherA: name});
                                        }}
                                    />
                                </div>
                                <div className="col-lg-3" style={{display: "flex"}}>
                                    <Button component="a" className="btns filter-item"
                                            onClick={e => {
                                                filter({publisherA: publisher})
                                            }}
                                            target="_blank"
                                            style={{background: "#06c", color: "white"}}
                                            variant="tertiary">
                                        Filtrele
                                    </Button>

                                    <Button component="a" className="btns filter-item"
                                            onClick={e => {
                                                filter({publisherA: ''})
                                            }}
                                            target="_blank"
                                            style={{background: "#dc3545", color: "white"}}
                                            variant="tertiary">
                                        Sıfırla
                                    </Button>
                                </div>
                            </div>
                            <DataTable
                                title="Yayınevleri"
                                subHeader
                                subHeaderComponent={getSubHeaderComponent()}
                                subHeaderAlign={Alignment.LEFT}
                                progressComponent={<Spinner/>}
                                noDataComponent="Sonuç Bulunamadı"
                                highlightOnHover={true}
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                pagination
                                data-tag="allowRowEvents"
                                striped
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                            />
                        </div> : <><br/><br/>
                            <center><Spinner/></center>
                        </>}
                </div>
            </section>
        </>
    )
}
export default PublisherListForm;