import {Alert, Spinner} from "@patternfly/react-core";
import React, {useState} from "react";
import {saveRandomQuote} from "./api/apiCalls";

const RandomQuoteSaveForm = () => {
    const [quoteError, setQuoteError] = useState("");

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const [quote, setQuote] = useState("");
    const [source, setSource] = useState("");
    const [author, setAuthor] = useState("");

    const onSubmit = async event => {
        let err = false;
        setQuoteError(undefined);

        if (quote === undefined || quote.trim() === ""){
            setQuoteError("Alıntı boş olamaz");
            err = true;
        }

        if (err){
            return
        }

        event.preventDefault();
        setSending(true);
        const body = {
            "source": source,
            "quote": quote,
            "author": author,
        }
        try {
            await saveRandomQuote(body);
            setSuccess(true);
            await new Promise(r => setTimeout(r, 1500));
            window.location.reload();
        } catch (error) {
            setSending(false);
            setQuoteError(error.response.data.validationErrors.quote);
        }
        setSending(false);
    };

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {success ? <Alert className="alert_sign" variant="success" timeout="3000"
                                      title="Rastgele Alıntı Kaydedildi"/> : ""}
                    <div className="sign_info">
                        <div className="row">
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Rastgele Alıntı Ekle</h2>
                                <form action="#" className="">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Alıntı*</label>
                                        <textarea
                                            required
                                            name="quote"
                                            onChange={(e) => {
                                                setQuoteError(undefined)
                                                setQuote(e.target.value)
                                            }}
                                            type="text"/>
                                        <span style={{color: "red"}}> {quoteError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Yazar</label>
                                        <input
                                            required
                                            name="author"
                                            onChange={(e) => setAuthor(e.target.value)}
                                            type="text"/>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Kitap</label>
                                        <input
                                            required
                                            name="source"
                                            onChange={(e) => setSource(e.target.value)}
                                            type="text"/>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {!sending ? <button disabled={sending} type="button" onClick={onSubmit}
                                                            className="btn_three">Kaydet</button> : <Spinner/>}
                                    </div>
                                    <br/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default RandomQuoteSaveForm;