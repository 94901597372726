import React from 'react';
import AdminSignInFrom from '../components/AdminSignIn';

const AdminSignIn = () => {
    return (
        <div className="body_wrapper">
            <AdminSignInFrom />
        </div>
    )
}
export default AdminSignIn;