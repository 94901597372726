import {Button, Select, SelectOption, Spinner} from '@patternfly/react-core';
import React, {useEffect, useState} from "react";
import DataTable, {Alignment} from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import {deleteBook, getBookList, publishBook, unpublishBook} from "./api/apiCalls";
import {useHistory} from "react-router-dom";

export const PublishOptions = [
    <SelectOption key={0}> - </SelectOption>,
    <SelectOption key={1} value="1"> Onaylı </SelectOption>,
    <SelectOption key={2} value="0"> Onay Bekleyen </SelectOption>,
];

const BookListForm = (props) => {

    const history = useHistory();
    const [key, setKey] = useState(0);
    const [name, setName] = useState("");
    const [authorFilter, setAuthorFilter] = useState("");
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [params, setParams] = useState();
    const [optOpen, setOptOpen] = useState(false);
    const [published, setPublished] = useState("");
    const [link, setLink] = useState("");

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'Kapak Görseli',
            selector: row => <img src={row.imageUrl} style={{maxHeight: "100px"}}/>,
        },
        {
            name: 'Ad / Yıl',
            selector: row => <div><span>{row.name}</span> / <span>{row.year}</span></div>,
        },
        {
            name: 'Yazar',
            selector: row => getAuthors(row.authors),
        },
        {
            name: 'Oy / Bayes Puan',
            selector: row => <div>
                <span>{row?.totalVote ? row?.totalVote : "-"}</span> / <span>{row?.rate ? row?.rate : "-"}</span>
            </div>,
        },
        {
            name: 'Link',
            selector: row => row.storeURL ? <a target="_blank" href={row.storeURL}>URL</a> : "-",
            width: "60px",
        },
        {
            name: 'Yayınla/Kaldır',
            selector: row => row.isPublished === true ?
                <Button className="btn_get btn_hover pf-m-danger" style={{minWidth: "80px"}} onClick={() => {
                    unpublish(row.id)
                }}>Kaldır</Button>
                :
                <Button className="btn_get btn_hover pf-m-primary" style={{minWidth: "80px"}} onClick={() => {
                    publish(row.id)
                }}>Yayınla</Button>,
        },
        {
            name: 'Güncelle',
            selector: row => <Button className="btn_get btn_hover" style={{minWidth: "80px"}} onClick={() => {
                history.push(`/book/${row.id}`)
            }}>Güncelle</Button>,
        },
        {
            name: 'Sil',
            selector: row => <Button className="btn_get btn_hover pf-m-danger" style={{minWidth: "80px"}}
                                     onClick={() => {
                                         deleteBook(row.id)
                                             .then(r => filter({nameA: name, authorA: authorFilter, publishedA: published}))
                                     }}>Sil</Button>,
        },
    ];

    const publish = (id) => {
        publishBook(id)
            .then(r => filter({nameA: name, authorA: authorFilter, publishedA: published}))
    }

    const unpublish = (id) => {
        unpublishBook(id)
            .then(r => filter({nameA: name, authorA: authorFilter, publishedA: published}))
    }
    const getAuthors = (authors) => {
        let arr = []
        authors.forEach(element => {
            arr.push(element.name)
        });
        return arr
    }

    const fetchBooks = async page => {
        setLoading(true);

        if (params !== undefined) {
            const response = await getBookList("?" + params + `&page=${page}&size=${perPage}`);
            setData(response.data.data.content);
            setTotalRows(response.data.data.totalElements);
            setLoading(false);
        } else {
            const response = await getBookList(`?page=${page}&size=${perPage}&delay=1`);
            setData(response.data.data.content);
            setTotalRows(response.data.data.totalElements);
            setLoading(false);
        }
    };

    const handlePageChange = page => {
        fetchBooks(page - 1);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        page = page <= 0 ? 0 : page - 1;
        setPerPage(newPerPage);
        const response = await getBookList(`?page=${page}&size=${newPerPage}`);

        setData(response.data.data.content);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const filter = async ({nameA, authorA, publishedA}) => {
        setLoading(true);
        const params = new URLSearchParams({
            name: nameA ? nameA : nameA === '' ? nameA : name,
            author: authorA ? authorA : authorA === '' ? authorA : authorFilter,
            isPublished: publishedA ? publishedA : publishedA === '' ? publishedA : published,
        }).toString();
        setParams(params);
        const data = await getBookList("?" + params);
        setData(data.data.data.content);
        setTotalRows(data.data.data.totalElements);
        setLoading(false);
    }

    const reset = async () => {
        setLoading(true);
        const params = new URLSearchParams({}).toString();
        setParams(params);
        const data = await getBookList("");
        setData(data.data.data.content);
        setTotalRows(data.data.data.totalElements);
        setLoading(false);
        setName("")
        setAuthorFilter("")
    }

    useEffect(() => {
        fetchBooks(0);
    }, [0]);

    function getSubHeaderComponent() {
        return <Button onClick={e => history.push('/kitap-ekle')}>Kitap ekle</Button>;
    }

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {loader ?
                        <div className="sign_info">
                            <div className="row" style={{paddingBottom: "10px"}}>
                                <div className="col-lg-3 nopadding">
                                    <input
                                        placeholder="Kitap İsmi"
                                        value={name}
                                        style={{width: "100%", height: "100%"}}
                                        onCut={(e) => reset()}
                                        onChange={(e) => {
                                            const name = e.target.value.trim() || '';
                                            setName(name);
                                            filter({nameA: name});
                                        }}
                                    /></div>
                                <div className="col-lg-3 nopadding">
                                    <input
                                        placeholder="Yazar İsmi"
                                        value={authorFilter}
                                        style={{width: "100%", height: "100%"}}
                                        onCut={(e) => reset()}
                                        onChange={(e) => {
                                            const name = e.target.value.trim() || '';
                                            setAuthorFilter(name);
                                            filter({authorA: name});
                                        }}
                                    /></div>
                                <div className="col-lg-3 nopadding">
                                    <Select
                                        placeholderText="Yayım Durumu"
                                        data-testid="publishedSelect"
                                        toggleId="kc-required-user-actions1"
                                        selections={published}
                                        isOpen={optOpen}
                                        onToggle={(isOpen) => {
                                            setOptOpen(isOpen);
                                        }}
                                        onSelect={(_, value) => {
                                            setPublished(value);
                                            setOptOpen(false);
                                            filter({publishedA: value});
                                        }}
                                        variant="typeahead"
                                    >
                                        {PublishOptions}
                                    </Select>
                                </div>
                                <div className="col-lg-3" style={{display: "flex"}}>
                                    <Button component="a" className="btns filter-item" onClick={e => {
                                        filter({nameA: name, authorA: authorFilter, publishedA: published})
                                    }}
                                            target="_blank"
                                            style={{background: "#06c", color: "white"}}
                                            variant="tertiary">
                                        Filtrele
                                    </Button>

                                    <Button component="a" className="btns filter-item" onClick={e => reset()}
                                            target="_blank"
                                            style={{background: "#dc3545", color: "white"}}
                                            variant="tertiary">
                                        Sıfırla
                                    </Button>
                                </div>
                            </div>
                            <DataTable
                                title="Kitaplar"
                                subHeader
                                subHeaderComponent={getSubHeaderComponent()}
                                subHeaderAlign={Alignment.LEFT}
                                highlightOnHover={true}
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                striped
                                pagination
                                paginationServer
                                progressComponent={<Spinner/>}
                                noDataComponent="Sonuç Bulunamadı"
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                            />
                        </div> : <><br/><br/>
                            <center><Spinner/></center>
                        </>}
                </div>
            </section>
        </>
    )
}
export default BookListForm;