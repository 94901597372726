import React from 'react';
import AuthorUpdateForm from '../components/AuthorUpdateForm';
import CustomNavbar from '../components/CustomNavbar';

const AuthorUpdate = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" hbtnClass="d-" />
            <AuthorUpdateForm />
        </div>
    )
}
export default AuthorUpdate;