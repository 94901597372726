import {Alert, Button} from "@patternfly/react-core";
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {changePass, user} from "./api/apiCalls";

const AccountDetailsForm = () => {
    const [currentUser, setUser] = useState({});
    const [key] = useState(0);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newPassword2Error, setNewPassword2Error] = useState(false);
    const [missmatch, setMissmatch] = useState(false);

    const [oldPasswordErrorApi, setOldPasswordErrorApi] = useState(false);
    const [newPasswordErrorApi, setNewPasswordErrorApi] = useState(false);
    const [newPassword2ErrorApi, setNewPassword2ErrorApi] = useState(false);

    const [oldPasswordErrorApiM, setOldPasswordErrorApiM] = useState("");
    const [newPasswordErrorApiM, setNewPasswordErrorApiM] = useState("");
    const [newPassword2ErrorApiM, setNewPassword2ErrorApiM] = useState("");

    const [success, setSuccess] = useState(false);

    const history = useHistory();
    const getUser = async () => {
        const apiCall = await user();
        setUser(apiCall.data.data);
    }


    const logout = () => {
        localStorage.clear();
        history.push("/");
        window.location.reload();
    };

    useEffect(() => {
        getUser();
    }, [key])


    const changePassword = () => {
        setNewPasswordErrorApi(false);
        setNewPassword2ErrorApi(false);
        setOldPasswordErrorApi(false);


        if (oldPassword === "") {
            setOldPasswordError(true);
        }
        if (newPassword === "") {
            setNewPasswordError(true);
        }
        if (newPassword2 === "") {
            setNewPassword2Error(true);
            return false;
        }
        if (newPassword !== newPassword2) {
            setMissmatch(true);
            return false;
        }
        const body = {
            oldPassword,
            newPassword,
            newPassword2,
        }
        changePass(body).then((response) => {
            if (response.status === 200) {
                setSuccess(true)
                new Promise(r => setTimeout(r, 1500));
                window.location.reload();
            }
            if (response.response?.data?.validationErrors !== null) {
                setNewPasswordErrorApi(true);
                setNewPasswordErrorApiM(response.response?.data?.validationErrors?.newPassword);

                setNewPassword2ErrorApi(true);
                setNewPassword2ErrorApiM(response.response?.data?.validationErrors?.newPassword2);
            } else {
                setOldPasswordErrorApi(true);
                setOldPasswordErrorApiM(response.response?.data.message);
            }
        })
    }

    return (
        <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                <i className="ti-receipt"/>
                                <h6 className="f_p f_600 f_size_18 t_color3">Hesap Bilgileri</h6>
                            </div>
                            <div className="info_item">
                                <h6>Ad</h6>
                                <p>{currentUser?.name}</p>
                            </div>
                            <div className="info_item">
                                <h6>Soyad</h6>
                                <p>{currentUser?.surname}</p>
                            </div>
                            <div className="info_item">
                                <h6>Email</h6>
                                <p>{currentUser?.mail}</p>
                            </div>
                            <br/>
                            <Button className="btn_get btn_hover" onClick={logout}>Çıkış Yap</Button>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        {success ? <Alert className="alert_sign" variant="success" timeout="3000"
                                          title="Parola Güncellendi"/> : ""}
                        <div className="login_info">
                            <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Parola Güncelle </h2>
                            <h4 className="f_p f_400 f_size_14 t_color3 mb_40">Yeni parola en az 6 karakterli
                                olmalıdır</h4>

                            <div className="form-group text_box">
                                <label className="f_p text_c f_400">Mevcut Parola</label>
                                <input type="password" onChange={(e) => {
                                    setOldPassword(e.target.value)
                                    setOldPasswordError(null)
                                }}/>
                                {oldPasswordError ? <span style={{color: "red"}}>Eski parola boş olamaz</span> : ""}
                                {oldPasswordErrorApi ? <span style={{color: "red"}}>{oldPasswordErrorApiM}</span> : ""}
                            </div>
                            <div className="form-group text_box">
                                <label className="f_p text_c f_400">Yeni Parola</label>
                                <input type="password" onChange={(e) => {
                                    setNewPassword(e.target.value)
                                    setNewPasswordError(null)
                                }}/>
                                {newPasswordError ? <span style={{color: "red"}}>Yeni parola boş olamaz</span> : ""}
                                {newPasswordErrorApi ? <span style={{color: "red"}}>{newPasswordErrorApiM}</span> : ""}
                            </div>
                            <div className="form-group text_box">
                                <label className="f_p text_c f_400">Yeni Parola Tekrar</label>
                                <input type="password" onChange={(e) => {
                                    setNewPassword2(e.target.value)
                                    setNewPassword2Error(null)
                                }}/>
                                {newPassword2Error ?
                                    <span style={{color: "red"}}>Yeni parola tekrarı boş olamaz</span> : ""}
                                {newPassword2ErrorApi ?
                                    <span style={{color: "red"}}>{newPassword2ErrorApiM}</span> : ""}
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <button onClick={changePassword}
                                        className="btn_three">Parolamı Güncelle</button>
                            </div>
                            {missmatch && <span style={{color: "red"}}>Parolalar eşleşmiyor!</span>}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AccountDetailsForm;