import {Button, Spinner} from '@patternfly/react-core';
import React, {useEffect, useState} from "react";
import DataTable, {Alignment} from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import {deleteQuote, getQuote} from "./api/apiCalls";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {useHistory} from "react-router-dom";

const QuoteListForm = (props) => {
    const history = useHistory();
    const [key] = useState(0);
    const [text, setText] = useState("");
    const [quoteFilter, setQuoteFilter] = useState("");
    const [loader] = useState(true);
    const [bookNameFilter, setBookNameFilter] = useState("");
    const [countFilter, setCountFilter] = useState();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [params, setParams] = useState("");

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'Alıntı',
            selector: row => row.quote ? row.quote : "-",
            maxWidth: "500px"
        },
        {
            name: 'Kitap İsmi',
            selector: row => row.bookName ? row.bookName : "-",
        },
        {
            name: 'Favori Sayısı',
            selector: row => row.count,
        },
        {
            name: 'Yayım Durumu',
            selector: row => row.active === true ? "Evet" : "Hayır",
        }, {
            name: 'Sil',
            selector: row => <Button className="btn_get btn_hover pf-m-danger" style={{minWidth: "80px"}}
                                     onClick={() => {
                                         deleteQuote(row.id)
                                         filter()
                                         filter()
                                     }}>Sil</Button>,
        },
    ];

    const getPopUp = (data) => {
        setText(data.quote)
        document.getElementById("modalBtn").click();
    }

    const fetchQuotes = async page => {
        setLoading(true);

        if (params !== undefined) {
            const response = await getQuote(`?${params}&page=${page}&size=${perPage}`);
            setData(response?.data?.data?.content);
            setTotalRows(response?.data?.data?.totalElements);
            setLoading(false);
        } else {
            const response = await getQuote(`?page=${page}&size=${perPage}&delay=1`);
            setData(response?.data?.data?.content);
            setTotalRows(response?.data?.data?.totalElements);
            setLoading(false);
        }
    };

    const handlePageChange = page => {
        fetchQuotes(page - 1);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        page = page - 1
        setPerPage(newPerPage);
        const response = await getQuote(`?page=${page}&size=${newPerPage}`);
        setData(response?.data?.data?.content);
        setLoading(false);
    };

    const filter = async ({quoteA, bookNameA, countA}) => {
        const params = new URLSearchParams({
            quote: quoteA ? quoteA : quoteA === '' ? '' : quoteFilter,
            bookName: bookNameA ? bookNameA : bookNameA === '' ? '' : bookNameFilter,
            count: countA ? countA : countA === '' ? '' : countFilter,
        }).toString();
        const data = await getQuote("?" + params);
        setData(data?.data?.data?.content);
        setTotalRows(data?.data?.data?.totalElements);
        setLoading(false);
        setParams(params);
    }

    const reset = async () => {
        setQuoteFilter("")
        setBookNameFilter("")
        setCountFilter(0)
        setParams("");
        const data = await getQuote("");
        setData(data?.data?.data?.content);
        setTotalRows(data?.data?.data?.totalElements);
        setLoading(false);
        setQuoteFilter("")
        setCountFilter(0)
        setBookNameFilter("")
    }

    useEffect(() => {
        fetchQuotes(0);
    }, [key]);

    function getSubHeaderComponent() {
        return <Button onClick={e => history.push('/alinti-ekle')}>Alıntı ekle</Button>;
    }
    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    <Popup
                        trigger={<button id='modalBtn' style={{"display": "none"}}></button>}
                        modal>
                        {text}
                    </Popup>
                    {loader ?
                        <div className="sign_info">
                            <div className="row" style={{paddingBottom: "10px"}}>
                                <div className="col-lg-3 nopadding">
                                    <input
                                        placeholder="Alıntı"
                                        value={quoteFilter}
                                        style={{width: "100%", height: "100%"}}
                                        onChange={e => {
                                            const value = e.target.value.trim() || '';
                                            setQuoteFilter(value)
                                            filter({quoteA: value});
                                        }}
                                    /></div>
                                <div className="col-lg-3 nopadding">
                                    <input
                                        placeholder="Kitap İsmi"
                                        value={bookNameFilter}
                                        style={{width: "100%", height: "100%"}}
                                        onChange={e => {
                                            const value = e.target.value.trim() || '';
                                            setBookNameFilter(value)
                                            filter({bookNameA: value});
                                        }}
                                    />
                                </div>
                                <div className="col-lg-3 nopadding">
                                    <input
                                        type="number"
                                        id="countInput"
                                        style={{width: "100%", height: "100%"}}
                                        placeholder="Favori Sayısı (minimum)"
                                        value={countFilter}
                                        onChange={e => {
                                            const value = e.target.valueAsNumber || 0;
                                            setCountFilter(value)
                                            filter({countA: value});
                                        }}
                                    /></div>
                                <div className="col-lg-3" style={{display: "flex"}}>
                                    <Button component="a" className="btns filter-item" onClick={event => {
                                        filter({
                                            quoteA: quoteFilter,
                                            bookNameA: bookNameFilter,
                                            countA: countFilter
                                        })
                                    }}
                                            target="_blank"
                                            style={{background: "#06c", color: "white"}}
                                            variant="tertiary">
                                        Filtrele
                                    </Button>

                                    <Button component="a" className="btns filter-item" onClick={event => reset()}
                                            target="_blank"
                                            style={{background: "#dc3545", color: "white"}}
                                            variant="tertiary">
                                        Sıfırla
                                    </Button>
                                </div>
                            </div>
                            <DataTable
                                title="Alıntılar"
                                subHeader
                                subHeaderComponent={getSubHeaderComponent()}
                                subHeaderAlign={Alignment.LEFT}
                                progressComponent={<Spinner/>}
                                noDataComponent="Sonuç Bulunamadı"
                                highlightOnHover={true}
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                pagination
                                data-tag="allowRowEvents"
                                striped
                                onRowClicked={(row, event) => {
                                    getPopUp(row)
                                }}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                            />
                        </div> : <><br/><br/>
                            <center><Spinner/></center>
                        </>}
                </div>
            </section>
        </>
    )
}
export default QuoteListForm;