import {Alert, Spinner} from "@patternfly/react-core";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {saveAuthor} from "./api/apiCalls";

const AuthorSaveForm = () => {
    const history = useHistory();
    const [msg, setmsg] = useState();

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);


    const [name, setName] = useState("");
    const [about, setAbout] = useState("");
    const [imageUrl, setImageUrl] = useState("");

    const [nameError, setNameError] = useState("");
    const [aboutError, setAboutError] = useState("");

    const onSubmit = async event => {
        let err = false;
        setNameError(null);
        setAboutError(null);
        if (name === "" || name.trim() === "") {
            setNameError("Yazar boş olamaz");
            err = true;
        }
        if (about === "" || about.trim() === "") {
            setAboutError("Yazar hakkında boş olamaz");
            err = true;
        }


        if (err){
            return
        }
        event.preventDefault();
        setSending(true);
        const body = {
            name,
            about,
            imageUrl,
        }
        try {
            await saveAuthor(body);
            setmsg("Yazar Kaydedildi")
            setSuccess(true);
            await new Promise(r => setTimeout(r, 1500));
            window.location.reload();
        } catch (error) {
            setmsg(error.response.data.validationErrors)
            setSuccess(false);
            setSending(false);
            setNameError(error.response.data.validationErrors.name);
            setAboutError(error.response.data.validationErrors.about);
        }
        setSending(false);
    };

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {success ? <Alert className="popup-fixed alert_sign" variant="success" timeout="3000" title={msg}/> : ""}
                    <div className="sign_info">
                        <div className="row">
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Yazar Ekle</h2>
                                <form action="#" className="">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Yazar İsmi*</label>
                                        <input type="text" name="name" onChange={(e) => {
                                            setName(e.target.value)
                                            setNameError(null)
                                        }}
                                               required/>
                                        <span style={{color: "red"}}> {nameError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Yazar Hakkında*</label>
                                        <input type="text" name="author" onChange={(e) => {
                                            setAbout(e.target.value)
                                            setAboutError(null)
                                        }}/>
                                        <span style={{color: "red"}}> {aboutError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Yazar Görsel Url</label>
                                        <input type="text" name="author" onChange={(e) => setImageUrl(e.target.value)}/>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {!sending ? <button disabled={sending} type="button" onClick={onSubmit}
                                                            className="btn_three">Kaydet</button> : <Spinner/>}
                                    </div>
                                    <br/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default AuthorSaveForm;