import {Alert, Spinner} from "@patternfly/react-core";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {getAuthorList, getBookByID, getPublisherList, updateBook} from "./api/apiCalls";

const BookUpdateForm = () => {
    const history = useHistory();
    const {id} = useParams();
    const [msg, setmsg] = useState();

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [isLoadingAsync, setIsLoadingAsync] = useState(false);

    const [name, setName] = useState();
    const [about, setAbout] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [storeURL, setStoreURL] = useState();


    const [nameError, setNameError] = useState("");
    const [aboutError, setAboutError] = useState("");
    const [imageUrlError, setImageUrlError] = useState("");
    const [storeURLError, setStoreURLError] = useState("");
    const loadAuthorOptions = async (text) => {
        try {
            const response = await getAuthorList("?name=" + text);
            return response.data.data.content;
        } catch (error) {
        }
    };
    const getOneAuthor = async (authorId) => {
        try {
            const response = await getOneAuthor(authorId);
            return response.data.data.content;
        } catch (error) {
        }
    };

    const loadPublisherOptions = async (text) => {
        try {
            const response = await getPublisherList("?name=" + text);
            return response.data.data.content;
        } catch (error) {
        }
    };

    const onSubmit = async event => {
        let error = false;
        setApiError(false);
        if (name == null) {
            setNameError("Kitap ismi boş olamaz");
            error = true;
        } else {
            setNameError(null);
        }
        if (about == null) {
            setAboutError("Kitap ismi boş olamaz");
            error = true;
        } else {
            setAboutError(null);
        }
        if (storeURL == null) {
            setStoreURLError("Satın alma linki boş olamaz");
            error = true;
        } else {
            setStoreURLError(null);
        }

        if (!error) {
            event.preventDefault();
            setSending(true);
            const body = {
                name,
                about,
                imageUrl,
                storeURL
            }
            console.log(body)

            try {
                let response = await updateBook(id, body);
                if (response.status === 200) {
                    setmsg("Kitap Güncellendi")
                    setSuccess(true);
                    await new Promise(r => setTimeout(r, 1500));
                    window.location.reload();
                } else {
                    setApiError(true);
                    setmsg(response.response.data?.message)
                    setSuccess(false);
                    setSending(false);
                    setNameError(response?.response?.data?.validationErrors?.name);
                    setAboutError(response?.response?.data?.validationErrors?.about);
                    setImageUrlError(response?.response?.data?.validationErrors?.imageUrl);
                    setStoreURLError(response?.response?.data?.validationErrors?.storeURL);
                }
            } catch (error) {
                setApiError(true);
                setmsg(error.response.data.validationErrors)
                setSuccess(false);
                setSending(false);
                setNameError(error?.response?.data?.validationErrors?.name);
                setAboutError(error?.response?.data?.validationErrors?.about);
                setImageUrlError(error?.response?.data?.validationErrors?.imageUrl);
                setStoreURLError(error.response.data?.validationErrors?.storeURL);
            }
            setSending(false);
        }
    };

    const getBook = async id => {
        const resp = await getBookByID(id);
        const book = resp.data.data.book;
        setName(book.name);
        setAbout(book.about);
        setImageUrl(book.imageUrl);
        setStoreURL(book.storeURL);
        return book
    }

    useEffect(() => {
        getBook(id)
            .then(r => console.log(r));
    }, [0]);

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {success ?
                        <Alert className="alert_sign popup-fixed" variant="success" timeout="3000" title={msg}/> : ""}
                    {apiError ?
                        <Alert className="alert_sign popup-fixed" variant="danger" timeout="10000" title={msg}/> : ""}
                    <div className="sign_info">
                        <div className="row">
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Kitap Ekle</h2>
                                <form action="#" className="">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Kitap İsmi*</label>
                                        <input type="text" name="name" value={name}
                                               onChange={(e) => {
                                                   setName(e.target.value)
                                                   setNameError(null)
                                               }}
                                               required/>
                                        <span style={{color: "red"}}> {nameError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Kitap Hakkında*</label>
                                        <textarea rows="5" name="abou" value={about} style={{lineHeight: "1.5rem"}}
                                                  onChange={(e) => {
                                                      setAbout(e.target.value)
                                                      setAboutError(null);
                                                  }}/>
                                        <span style={{color: "red"}}> {aboutError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Kapak Resmi*</label>
                                        <small>
                                            <a href="/uploadImage" target="_blank"><b> (görsel yükle)</b></a>
                                        </small>
                                        <input type="text" name="imageUrl" value={imageUrl}
                                               onChange={(e) => setImageUrl(e.target.value)}/>
                                        <span style={{color: "red"}}> {imageUrlError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Satın Alma Linki</label>
                                        <input type="text" name="storeURL" value={storeURL} onChange={(e) => {
                                            setStoreURL(e.target.value)
                                            setStoreURLError(null);
                                        }}
                                               required/>
                                        <span style={{color: "red"}}> {storeURLError} </span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {!sending ? <button disabled={sending} type="button" onClick={onSubmit}
                                                            className="btn_three">Güncelle</button> : <Spinner/>}
                                    </div>
                                    <br/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default BookUpdateForm;