import React, {useEffect, useState} from 'react';
import AuthorListForm from '../components/AuthorListForm';
import CustomNavbar from '../components/CustomNavbar';
import {Denied} from '../components/Denied';

const AuthorList = () => {
    const [isSap, setIsSap] = useState(false);
    useEffect(() => {
        const data = localStorage.getItem('sap');
        if (data === "true") {
            setIsSap(true);
        }
    }, [isSap]);
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" hbtnClass="d-" />
            {isSap ? <AuthorListForm /> : <Denied />}
        </div>
    )
}
export default AuthorList;