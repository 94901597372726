import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';
import {Switch, useDarkreader} from 'react-darkreader';

const CustomNavbar = (props) => {
    const {isLoggedIn} = useSelector(store => ({
        isLoggedIn: store.isLoggedIn
    }));
    const [isSap, setIsSap] = useState(false);
    let {mClass, nClass, cClass, slogo} = props;


    const initialDarkMode = localStorage.getItem('darkMode') === 'true';
    const [isDark, {toggle}] = useDarkreader(initialDarkMode);

    const handleToggle = () => {
        toggle();
        localStorage.setItem('darkMode', !isDark);
        if (isDark) {
            // remove custom dark mode CSS element
            document.querySelectorAll("#customDarkMode").forEach(el => el.remove());
        }
    };

    useEffect(() => {
        // add custom dark mode CSS element into the head
        if (localStorage.getItem('darkMode') === 'true') {
            let element = document.createElement("link");
            element.rel = "stylesheet";
            element.id = "customDarkMode";
            element.href = "static/css/dark.css";
            document.head.appendChild(element)
        }


        const data = localStorage.getItem('sap');
        if (data === "true") {
            setIsSap(true);
        }
        localStorage.setItem('darkMode', isDark);
    }, [isSap]);

    return (
        <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
            <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/kirmizibalon.png")} style={{borderRadius: "50%"}} width={100}
                                 alt=""/>
                            <img src={require("../img/kirmizibalon.png")} style={{borderRadius: "50%"}} width={50}
                                 alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        {
                            !isSap
                                ?
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                        {!isLoggedIn &&
                                            <li className="nav-item">
                                                <NavLink title="Pricing3" className="nav-link" to="/SignIn">Giriş
                                                    Yap</NavLink>
                                            </li>}
                                    </ul>
                                </div>
                                :
                                <li className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                        <li className="dropdown submenu nav-item">
                                            <Link to="#" title="App Ana Ekran" className="dropdown-toggle nav-link"
                                                  data-toggle="dropdown" role="button" aria-haspopup="true"
                                                  aria-expanded="false">App Ana Ekran</Link>
                                            <ul role="menu" className=" dropdown-menu">
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" to='/app-ana-ekran'>App Ana
                                                        Ekran</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" to='/app-ana-ekran-duzenle'>App Ana
                                                        Ekran
                                                        Düzenle</NavLink></li>
                                            </ul>
                                        </li>

                                        <li className="dropdown submenu nav-item">
                                            <Link to="#" title="Alıntılar" className="dropdown-toggle nav-link"
                                                  data-toggle="dropdown" role="button" aria-haspopup="true"
                                                  aria-expanded="false">Alıntılar</Link>
                                            <ul role="menu" className=" dropdown-menu">
                                                <li className="nav-item">
                                                    <NavLink exact title="Alıntılar" className="nav-link"
                                                             to='/alintilar'>Alıntılar</NavLink></li>
                                                <li className="nav-item">
                                                    <NavLink exact title="Alıntı Ekle" className="nav-link"
                                                             to='/alinti-ekle'>Alıntı Ekle</NavLink></li>
                                                <li className="nav-item">
                                                    <NavLink exact title="Rastgele Alıntılar" className="nav-link"
                                                             to='/rastgele-alintilar'>Rastgele Alıntılar</NavLink></li>
                                                <li className="nav-item">
                                                    <NavLink exact title="Rastgele Alıntı Ekle" className="nav-link"
                                                             to='/rastgele-alinti-ekle'>Rastgele Alıntı Ekle</NavLink>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="dropdown submenu nav-item">
                                            <Link to="#" title="Kitaplar" className="dropdown-toggle nav-link"
                                                  data-toggle="dropdown" role="button" aria-haspopup="true"
                                                  aria-expanded="false">Kitaplar</Link>
                                            <ul role="menu" className=" dropdown-menu">
                                                <li className="nav-item">
                                                    <NavLink exact className="nav-link" title="Kitaplar"
                                                             to="/kitaplar">Kitaplar</NavLink></li>
                                                <li className="nav-item">
                                                    <NavLink exact className="nav-link" title="Kitap Ekle"
                                                             to='/kitap-ekle'>Kitap Ekle</NavLink></li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link"
                                                             to="kategoriler">Kategoriler</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link"
                                                             to='/kategori-ekle'>Kategori Ekle</NavLink>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="dropdown submenu nav-item">
                                            <Link to="#" title="Yazar/Yayınevi" className="dropdown-toggle nav-link"
                                                  data-toggle="dropdown" role="button" aria-haspopup="true"
                                                  aria-expanded="false">Yazar/Yayınevi</Link>
                                            <ul role="menu" className=" dropdown-menu">
                                                <li className="nav-item">
                                                    <NavLink exact className="nav-link" title="Yazarlar"
                                                             to='/yazarlar'>Yazarlar</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink exact className="nav-link" title="Yazar Ekle"
                                                             to='/yazar-ekle'>Yazar Ekle</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink exact className="nav-link" title="Yayınevleri"
                                                             to='/yayinevleri'>Yayınevleri</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink exact className="nav-link" title="Yayınevi Ekle"
                                                             to='/yayinevi-ekle'>Yayınevi Ekle</NavLink>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink title="Kullanıcılar" className="nav-link"
                                                     to="/kullanicilar">Kullanıcılar</NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink title="Yorumlar" className="nav-link"
                                                     to="/yorumlar">Yorumlar</NavLink>
                                        </li>

                                        <li className="dropdown submenu nav-item">
                                            <Link to="#" title="Aksiyonlar" className="dropdown-toggle nav-link"
                                                  data-toggle="dropdown" role="button" aria-haspopup="true"
                                                  aria-expanded="false">Aksiyonlar</Link>
                                            <ul role="menu" className=" dropdown-menu">
                                                <li className="nav-item">
                                                    <NavLink className="nav-link"
                                                             to="/cache-sil">Cache Temizle</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link"
                                                             to='/versiyonlar'>Versiyon Güncelle</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link"
                                                             to='/gorseller'>Görseller (Google Drive)</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link"
                                                             to='/gorsel-yukle'>Görsel Yükle</NavLink>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink title="Hesabım" className="nav-link"
                                                     to="/hesabim">Hesabım</NavLink>
                                        </li>

                                        <li style={{alignSelf: "center", margin: "15px"}}>
                                            <Switch checked={isDark} onChange={handleToggle}/>
                                        </li>

                                    </ul>

                                </li>
                        }
                    </div>
                </nav>
            </header>
        </Sticky>
    );
}

export default CustomNavbar;