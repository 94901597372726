import {Alert, Spinner} from "@patternfly/react-core";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {saveAuthor, saveCategories} from "./api/apiCalls";

const CategorySaveForm = () => {
    const history = useHistory();
    const [msg, setmsg] = useState("");

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);


    const [name, setName] = useState("");

    const [nameError, setNameError] = useState("");

    const onSubmit = async event => {
        setNameError(null);
        if (name === "" || name.trim() === "") {
            setNameError("Kategori boş olamaz");
            return
        }

        event.preventDefault();
        setSending(true);
        try {
            await saveCategories({
                "category": name
            });
            setmsg("Kategori kaydedildi. Sayfa yenileniyor..")
            setSuccess(true);
            await new Promise(r => setTimeout(r, 1000));
            window.location.reload();
        } catch (error) {
            setmsg(error?.response?.data?.validationErrors)
            setNameError(error?.response?.data?.validationErrors.name);
            setSuccess(false);
            setSending(false);
        }
        setSending(false);
    };

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {success ?
                        <Alert className="popup-fixed alert_sign" variant="success" timeout="3000" title={msg}/> : ""}
                    <div className="sign_info">
                        <div className="row">
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Kategori Ekle</h2>
                                <form action="#" className="">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Kategori*</label>
                                        <input type="text" name="name"
                                               onChange={(e) => {
                                                   setName(e.target.value)
                                                   setNameError(null)
                                               }}
                                               required/>
                                        <span style={{color: "red"}}> {nameError} </span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {!sending ?
                                            <button disabled={sending}
                                                    type="button"
                                                    onClick={onSubmit}
                                                    className="btn_three">Kaydet</button> : <Spinner/>
                                        }
                                    </div>
                                    <br/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CategorySaveForm;