import {Alert, Spinner} from "@patternfly/react-core";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import AsyncSelect from "react-select/async";
import {saveQuote, searchBook} from "./api/apiCalls";

const QuoteSaveForm = () => {
    const history = useHistory();

    const [quoteError, setQuoteError] = useState("");
    const [bookError, setBookError] = useState("");

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isLoadingAsync, setIsLoadingAsync] = useState(false);

    const [quote, setQuote] = useState("");
    const [input, setInput] = useState("");

    const loadOptions = async (text) => {
        try {
            const response = await searchBook(text);
            return response.data.data.content;
        }
        catch (error) {
        }
    };
    const onSubmit = async event => {
        let err = false;
        setQuoteError(undefined);
        setBookError(undefined);

        if (quote === undefined || quote.trim() === ""){
            setQuoteError("Alıntı boş olamaz");
            err = true;
        }
        if (input.id === undefined){
            setBookError("Kitap boş olamaz");
            err = true;
        }

        if (err){
            return
        }
        event.preventDefault();
        setSending(true);
        const body = {
            "bookId": input.id,
            "quote": quote
        }
        try {
            await saveQuote(body);
            setSuccess(true);
            await new Promise(r => setTimeout(r, 1500));
            window.location.reload();
        }
        catch (error) {
            setSending(false);
            setQuoteError(error.response.data.validationErrors.quote);
            setBookError(error.response.data.validationErrors.bookId);
        }
        setSending(false);
    };

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {success ? <Alert className="alert_sign" variant="success" timeout="3000" title="Alıntı Kaydedildi" /> : ""}
                    <div className="sign_info">
                        <div className="row">
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Alıntı Ekle</h2>
                                <h5 className="f_p f_400 f_size_14 t_color3 mb_40">Toplu alıntı eklemek için her alıntı arasına artı işareti (<small style={{"color":"red"}}>+</small>) koyulmalıdır</h5>
                                <form action="#" className="">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Alıntı</label>
                                        <textarea
                                            required
                                            name="quote"
                                            onChange={(e) => {
                                                setQuote(e.target.value)
                                                setQuoteError(undefined);
                                            }}
                                        />
                                        <span style={{ color: "red" }}> {quoteError} </span>
                                    </div>

                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Kitap <small>(yazar ismi ile de arayabilirsiniz)</small></label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions
                                            isLoading={isLoadingAsync}
                                            getOptionLabel={(e) => e.name}
                                            getOptionValue={(e) => e.id}
                                            loadOptions={loadOptions}
                                            noOptionsMessage={({ inputValue }) => (
                                                <span>{inputValue} için sonuç yok</span>
                                            )}
                                            onChange={(value) => {
                                                setInput(value)
                                                setBookError(undefined);
                                            }}
                                        />
                                        <span style={{ color: "red" }}> {bookError} </span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {!sending ? <button disabled={sending} type="button" onClick={onSubmit} className="btn_three">Kaydet</button> : <Spinner />}
                                    </div>
                                    <br />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
    )
}
export default QuoteSaveForm;