import {Alert, Button, Spinner} from '@patternfly/react-core';
import React, {useEffect, useState} from "react";
import DataTable, {Alignment} from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import {deleteRandomQuote, getRandomQuote} from "./api/apiCalls";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {useHistory} from "react-router-dom";

const RandomQuoteListForm = (props) => {
    const [key] = useState(0);
    const [text, setText] = useState("");
    const [quoteFilter, setQuoteFilter] = useState("");
    const [loader] = useState(true);
    const [bookNameFilter, setBookNameFilter] = useState("");
    const [authorFilter, setAuthorFilter] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [params, setParams] = useState();
    const history = useHistory();

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'Alıntı',
            selector: row => row.quote ? row.quote : "-",
            maxWidth: "500px"
        },
        {
            name: 'Kitap İsmi',
            selector: row => row.source ? row.source : "-",
        },
        {
            name: 'Yazar İsmi',
            selector: row => row.author,
        }, {
            name: 'Sil',
            selector: row =>
                <Button className="btn_get btn_hover pf-m-danger" style={{minWidth: "80px"}}
                        onClick={() => {
                            setSuccessDelete(false)
                            deleteRandomQuote(row.id)
                                .then(r => {
                                    if (r.status === 200){
                                        setSuccessDelete(true)
                                        reset()
                                    }
                                })
                        }}>Sil</Button>,
        },
    ];

    const getPopUp = (data) => {
        setText(data.quote)
        document.getElementById("modalBtn").click();
    }

    const fetchRandomQuotes = async page => {
        setLoading(true);

        if (params !== undefined) {
            const response = await getRandomQuote("?" + params + `&page=${page}&size=${perPage}`);
            setData(response.data.data.content);
            setTotalRows(response.data.data.totalElements);
            setLoading(false);
        } else {
            const response = await getRandomQuote(`?page=${page}&size=${perPage}&delay=1`);
            setData(response.data.data.content);
            setTotalRows(response.data.data.totalElements);
            setLoading(false);
        }
    };

    const handlePageChange = page => {

        fetchRandomQuotes(page - 1);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        page = page - 1
        const response = await getRandomQuote(`?page=${page}&size=${newPerPage}`);

        setData(response.data.data.content);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const filter = async ({quoteA, sourceA, authorA}) => {
        const params = new URLSearchParams({
            "quote": quoteA ? quoteA : quoteA === '' ? '' : quoteFilter,
            "source": sourceA ? sourceA : sourceA === '' ? '' : bookNameFilter,
            "author": authorA ? authorA : authorA === '' ? '' : authorFilter
        }).toString();
        setParams(params);
        const data = await getRandomQuote("?" + params);
        setData(data.data.data.content);
        setTotalRows(data.data.data.totalElements);
        setLoading(false);
    }

    const reset = async () => {
        setParams("");
        setAuthorFilter("")
        setQuoteFilter("")
        setBookNameFilter("")
        const data = await getRandomQuote("");
        setData(data.data.data.content);
        setTotalRows(data.data.data.totalElements);
        setLoading(false);
    }

    useEffect(() => {
        fetchRandomQuotes(0);
    }, [key]);


    function getSubHeaderComponent() {
        return <Button onClick={e => history.push('/rastgele-alinti-ekle')}>Rastgele Alıntı ekle</Button>;
    }

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    <Popup
                        trigger={<button id='modalBtn' style={{"display": "none"}}></button>}
                        modal>
                        {text}
                    </Popup>
                    {loader ?
                        <div className="sign_info">
                            {successDelete ? <Alert className="alert_sign" variant="success" timeout="1500"
                                              title="Silindi"/> : ""}
                            <div className="row" style={{paddingBottom: "10px"}}>
                                <div className="col-lg-3">
                                    <input
                                        className={""}
                                        style={{width: "100%", height: "100%"}}
                                        placeholder="Alıntı"
                                        value={quoteFilter}
                                        onChange={(e) => {
                                            const value = e.target.value.trim() || '';
                                            setQuoteFilter(value)
                                            filter({quoteA: value});
                                        }}
                                    /></div>
                                <div className="col-lg-3">
                                    <input
                                        className={""}
                                        style={{width: "100%", height: "100%"}}
                                        placeholder="Kitap İsmi"
                                        value={bookNameFilter}
                                        onChange={(e) => {
                                            const value = e.target.value.trim() || '';
                                            setBookNameFilter(value)
                                            filter({sourceA: value});
                                        }}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <input
                                        className={""}
                                        style={{width: "100%", height: "100%"}}
                                        placeholder="Yazar İsmi"
                                        value={authorFilter}
                                        onChange={(e) => {
                                            const value = e.target.value.trim() || '';
                                            setAuthorFilter(value)
                                            filter({authorA: value});
                                        }}
                                    /></div>
                                <div className="col-lg-3" style={{display: "flex"}}>
                                    <Button component="a" className="btns filter-item"
                                            onClick={event => {
                                                filter({
                                                    quoteA: quoteFilter,
                                                    sourceA: bookNameFilter,
                                                    authorA: authorFilter
                                                })
                                            }}
                                            target="_blank"
                                            style={{background: "#06c", color: "white"}}
                                            variant="tertiary">
                                        Filtrele
                                    </Button>

                                    <Button component="a" className="btns filter-item" onClick={event => reset()}
                                            target="_blank"
                                            style={{background: "#dc3545", color: "white"}}
                                            variant="tertiary">
                                        Sıfırla
                                    </Button>
                                </div>
                            </div>
                            <DataTable
                                title="Rastgele Alıntılar (satıra tıklayarak pop-up olarak açabilirsin)"
                                subHeader
                                subHeaderComponent={getSubHeaderComponent()}
                                subHeaderAlign={Alignment.LEFT}
                                progressComponent={<Spinner/>}
                                noDataComponent="Sonuç Bulunamadı"
                                highlightOnHover={true}
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                pagination
                                data-tag="allowRowEvents"
                                striped
                                onRowClicked={(row, event) => {
                                    getPopUp(row)
                                }}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                            />
                        </div> : <><br/><br/>
                            <center><Spinner/></center>
                        </>}
                </div>
            </section>
        </>
    )
}
export default RandomQuoteListForm;