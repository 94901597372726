import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from 'react-redux';
import {useHistory} from "react-router-dom";
import {loginHandler} from '../redux/authActions';
import {Spinner} from "@patternfly/react-core";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const AdminSignInFrom = (props) => {
    const [key, setKey] = useState(0);
    const [mail, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const emptyDivRef = useRef(null);
    const sectionRef = useRef(null);

    const gTokenKey = "gToken";

    const handleVerify = async token => {
        localStorage.setItem(gTokenKey, token)
    }
    const onClickLogin = async event => {
        event.preventDefault();
        setLoading(true)
        setError(undefined);
        const creds = {
            "mail": mail,
            "password": password,
            "token": localStorage.getItem(gTokenKey)
        };
        try {
            const apiCall = await dispatch(loginHandler(creds));
            if (apiCall?.data?.data?.status === 200) {
                localStorage.setItem("sap", "true");
                window.location.reload();
            } else {
                setError("Yetkisiz kullanıcı");
                setLoading(false)
            }
        } catch (apiError) {
            setError("Hatalı kullanıcı/parola");
            setLoading(false)
        }
    };

    useEffect(() => {
        localStorage.clear()
        // Update the document title using the browser API
        window.scrollTo(0, 0)
        const url = window.location.href;

        const calculateHeight = () => {
            if (emptyDivRef.current && sectionRef.current) {
                const sectionHeight = sectionRef.current.clientHeight;
                const newScreenHeight = window.innerHeight - sectionHeight;

                emptyDivRef.current.style.height = `${newScreenHeight}px`;
                emptyDivRef.current.style.clear = 'both';
                emptyDivRef.current.style.width = '100%';
            }
        };

        calculateHeight();
        window.addEventListener('resize', calculateHeight);

        return () => {
            window.removeEventListener('resize', calculateHeight);
        };
    }, [key]);


    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LdstH0nAAAAANOEFNo5x2ANQwBh3zqt6DOo_StV" language="tr">
            <section ref={sectionRef} className="sign_in_area bg_color-admin sec_pad">
                <div className="container">
                    <div className="sign_info">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="sign_info_content">
                                    <h2 className="f_p f_400 f_size_30 mb-30">Hesabınıza Giriş Yapın</h2>
                                    <h4 className="f_p f_600 f_size_24 t_color3 mb_40">Yönetici Girişi</h4>
                                </div>
                            </div>
                            <div className="col-lg-7 ">
                                <div className="login_info">
                                    <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Giriş Yap</h2>
                                    <form action="/#" className="login-form sign-in-form">
                                        <div className="form-group text_box">
                                            <label className="f_p text_c f_400">E-Posta</label>
                                            <input type="text" onChange={(e) => setEmail(e.target.value)}/>
                                        </div>
                                        <div className="form-group text_box">
                                            <label className="f_p text_c f_400">Parola</label>
                                            <input type="password" onChange={(e) => setPassword(e.target.value)}/>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <GoogleReCaptcha onVerify={handleVerify}/>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            {loading ? <Spinner/> :
                                                <button type="submit"
                                                        onClick={onClickLogin}
                                                        className="btn_three">Giriş Yap</button>
                                            }
                                        </div>
                                        <br/>
                                        {error && <p style={{color: "red"}}>{error}</p>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={emptyDivRef}></div>
                </div>
            </section>
        </GoogleReCaptchaProvider>
    )
}
export default AdminSignInFrom;