import React, {useEffect, useState} from 'react';
import CustomNavbar from '../components/CustomNavbar';
import {Denied} from '../components/Denied';
import CommentListForm from "../components/CommentListForm";
import CategoryListForm from "../components/CategoryListForm";

const CategoryList = () => {
    const [isSap, setIsSap] = useState(false);
    useEffect(() => {
        const data = localStorage.getItem('sap');
        if (data === "true") {
            setIsSap(true);
        }
    }, [isSap]);
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" hbtnClass="d-"/>
            {isSap ? <CategoryListForm/> : <Denied/>}
        </div>
    )
}
export default CategoryList;