import * as ACTIONS from './Constants';
import {login} from '../components/api/apiCalls';

export const logoutSuccess = () => {
  return async function (dispatch) {
    dispatch({
      type: ACTIONS.LOGOUT_SUCCESS
    })
  }
};

export const loginSuccess = authState => {
  return {
    type: ACTIONS.LOGIN_SUCCESS,
    payload: authState
  };
};

export const updateSuccess = ({ displayName, image }) => {
  return {
    type: ACTIONS.UPDATE_SUCCESS,
    payload: {
      displayName,
      image
    }
  };
};

export const loginHandler = credentials => {
  return async function (dispatch) {
    const response = await login(credentials);
    if (!response?.data?.data?.roles?.includes("ROLE_ADMIN")) {
      return
    }
    const tok = response?.data?.data?.token;
    const authState = {
      ...response.data.user,
      token: tok,
    };
    dispatch(loginSuccess(authState));
    return response;
  };
};