import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import AccountDetailsForm from '../components/AccountDetailsForm';

const Faq = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" hbtnClass="d-"/>
            <AccountDetailsForm/>
        </div>
    )
}
export default Faq;