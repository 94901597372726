import React, {useEffect, useState} from "react";
import 'react-data-table-component-extensions/dist/index.css';
import 'reactjs-popup/dist/index.css';
import {
    deleteHomepageItemAdmin,
    getHomepageAdmin,
    getHomepageEditorsChoice,
    getHomepageLast20Books,
    getHomepageRecommendedBook,
    getHomepageRecommendedList,
    getHomepageTopBooks
} from "./api/apiCalls";
import DataTable, {Alignment} from "react-data-table-component";
import {Alert, Button, Spinner} from '@patternfly/react-core';
import {useHistory} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import AsyncSelect from "react-select/async";

const CommentListForm = (props) => {
    const [key] = useState(0);
    const [data, setData] = useState([]);
    const [type, setType] = useState("");
    const [msg, setMsg] = useState("");
    const [title, setTitle] = useState("");
    const [subHeaderText, setSubHeaderText] = useState("");

    const [success, setSuccess] = useState(false);
    const [selected, setSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const getColumns = () => {
        let defaultColumns = [
            {
                name: 'Kitap ID',
                selector: row => row?.id ? row?.id : "-",
            },
            {
                name: 'Kitap İsmi',
                selector: row => row?.name ? row?.name : "-",
            }
        ]
        if (type === "RECOMMENDED_BOOK") {
            defaultColumns.push(
                {
                    name: 'Kapak Fotoğrafı',
                    selector: row => <img src={row?.imageUrl} style={{maxHeight: "100px", padding: "5px"}} alt={""}/>,
                },
                {
                    name: 'Açıklama',
                    selector: row => row?.description ? row?.description : "-",
                },
                {
                    name: 'Sil',
                    selector: row => <Button className="btn_get btn_hover pf-m-danger" style={{minWidth: "80px"}}
                                             onClick={() => {
                                                 deleteItemConfirmation(row?.id, "0", row?.name)
                                             }}>Sil</Button>,
                }
            )
        } else if (type === "RECOMMENDED_LIST") {
            defaultColumns.push(
                {
                    name: 'Açıklama',
                    selector: row => row?.description ? row?.description : "-",
                },
                {
                    name: 'Sil',
                    selector: row => <Button className="btn_get btn_hover pf-m-danger" style={{minWidth: "80px"}}
                                             onClick={() => {
                                                 deleteItemConfirmation(row?.id, "1", row?.name)
                                             }}>Sil</Button>,
                }
            )
        } else if (type === "EDITORS_CHOICE") {
            defaultColumns.push(
                {
                    name: 'Açıklama',
                    selector: row => row?.description ? row?.description : "-",
                },
                {
                    name: 'Sil',
                    selector: row => <Button className="btn_get btn_hover pf-m-danger" style={{minWidth: "80px"}}
                                             onClick={() => {
                                                 deleteItemConfirmation(row?.id, "2", row?.name)
                                             }}>Sil</Button>,
                }
            )
        } else if (type === "TOP_LIST") {
            defaultColumns.push(
                {
                    name: 'Kapak Fotoğrafı',
                    selector: row => <img src={row.imageUrl} style={{maxHeight: "100px", padding: "5px"}} alt={""}/>,
                },
                {
                    name: 'Ortalama Puan',
                    selector: row => row.average ? row?.average : "-",
                },
                {
                    name: 'Bayes Puanı',
                    selector: row => row.wr ? row?.wr : "-",
                }
            )
        } else if (type === "LAST_BOOKS") {
            defaultColumns.push(
                {
                    name: 'Kapak Fotoğrafı',
                    selector: row => <img src={row.imageUrl} style={{maxHeight: "100px", padding: "5px"}} alt={""}/>,
                }
            )
        }

        return defaultColumns;
    }

    const deleteItemConfirmation = (bookId, typeId, bookName) => {
        setSuccess(false);
        confirmAlert({
            title: 'Sileyim mi? Bu işlem geri alınamaz',
            message: `${bookName} isimli kitabı bu listeden silmek üzeresin`,
            buttons: [
                {
                    label: 'Evet',
                    onClick: async () => {
                        setSuccess(false)
                        deleteHomepageItemAdmin(typeId, bookId)
                            .then(r => {
                                if (r.status === 200) {
                                    setSuccess(true)
                                    setMsg(`'${bookName}', listeden silindi`)
                                    fetchHomepage(type)
                                } else {
                                    setMsg(r.response?.data?.message)
                                }
                            })
                    }
                },
                {
                    label: 'Hayır',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    const fetchHomepage = async (type) => {
        setLoading(true);
        setSubHeaderText("")

        const response = await getHomepageAdmin();
        const filter = response.data.data.filter(item => item?.type === type);
        setType(filter[0]?.type);
        switch (type) {
            case "RECOMMENDED_BOOK":
                await getHomepageRecommendedBook()
                    .then(r => setData([r?.data?.data]))
                break
            case "RECOMMENDED_LIST":
                await getHomepageRecommendedList()
                    .then(r => setData(r?.data?.data?.content))
                break
            case "EDITORS_CHOICE":
                await getHomepageEditorsChoice()
                    .then(r => setData(r?.data?.data?.content))
                break
            case "TOP_LIST":
                await getHomepageTopBooks()
                    .then(r => {
                        setData(r?.data?.data)
                        setSubHeaderText("Bu liste her 12 saatte 1 defa yeniden hesaplanır")
                    })
                break
            case "LAST_BOOKS":
                await getHomepageLast20Books()
                    .then(r => {
                        setData(r?.data?.data)
                        setSubHeaderText("Bu liste tarihi sıraya göre en son eklenen 20 kitabı gösterir")
                    })
                break
            default:
                setType(filter[0]?.books);
        }
        setLoading(false);
    };

    const loadOptions = async (text) => {
        const r = await getHomepageAdmin();
        return r.data?.data;
    };

    useEffect(() => {
    }, [key]);

    return (
        <section className="sign_in_area bg_color sec_pad">
            <div className="container">
                <div className="sign_info">
                    {<Spinner style={{display: "none"}}/>}
                    {success ? <Alert className="popup-fixed alert_sign" variant="success" timeout="3000"
                                      title={msg}/> : ""}
                    <div className="container">
                        <div className="row">
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Uygulama Ana Ekranı</h2>
                                <h4 className="f_p f_300 f_size_14 t_color mb_40">Görüntülemek istediğiniz alanı
                                    seçiniz</h4>
                                <div className="form-group text_box" style={{zIndex: 5}}>
                                    <label className="f_p text_c f_400">Tip Seçimi</label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        isLoading={loading}
                                        getOptionLabel={(e) => e.title}
                                        getOptionValue={(e) => e.type}
                                        loadOptions={loadOptions}
                                        onChange={(value) => {
                                            fetchHomepage(value.type)
                                            setTitle(value.title)
                                            setSelected(true)
                                        }}
                                        noOptionsMessage={({inputValue}) => (
                                            <span>{inputValue} için sonuç yok</span>
                                        )}
                                    />
                                    <span style={{color: "red"}}>  </span>
                                </div>
                                {selected ?
                                    <DataTable
                                        title={title}
                                        subHeader
                                        subHeaderComponent={subHeaderText}
                                        subHeaderAlign={Alignment.LEFT}
                                        highlightOnHover={true}
                                        columns={getColumns()}
                                        data={data}
                                        progressPending={loading}
                                        striped
                                        progressComponent={<Spinner/>}
                                        noDataComponent="Sonuç Bulunamadı"
                                        data-tag="allowRowEvents"
                                    /> : ""}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CommentListForm;