import {Alert, Spinner} from "@patternfly/react-core";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import AsyncSelect from "react-select/async";
import {getAuthorList, getPublisherList, saveBook} from "./api/apiCalls";

const BookSaveForm = () => {
    const history = useHistory();
    const [msg, setmsg] = useState();

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isLoadingAsync, setIsLoadingAsync] = useState(false);

    const [authorIds, setAuthorIds] = useState([]);
    const [name, setName] = useState();
    const [about, setAbout] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [page, setPage] = useState();
    const [publisherId, setPublisherId] = useState();
    const [year, setYear] = useState();
    const [storeURL, setStoreURL] = useState();


    const [authorIdsError, setAuthorIdsError] = useState("");
    const [nameError, setNameError] = useState("");
    const [aboutError, setAboutError] = useState("");
    const [imageUrlError, setImageUrlError] = useState("");
    const [pageError, setPageError] = useState("");
    const [publisherIdError, setPublisherIdError] = useState("");
    const [yearError, setYearError] = useState("");
    const [storeURLError, setStoreURLError] = useState("");
    const loadAuthorOptions = async (text) => {
        try {
            const response = await getAuthorList("?name=" + text);
            return response.data.data.content;
        } catch (error) {
        }
    };

    const loadPublisherOptions = async (text) => {
        try {
            const response = await getPublisherList("?name=" + text);
            return response.data.data.content;
        } catch (error) {
        }
    };

    const onSubmit = async event => {
        let error = false;
        if (publisherId === undefined) {
            setPublisherIdError("Yayınevi boş olamaz");
            error = true;
        } else {
            setPublisherIdError(null);
        }
        if (authorIds.length === 0) {
            setAuthorIdsError("Yazar boş olamaz");
            error = true;
        } else {
            setAuthorIdsError(null);
        }
        if (year == null) {
            setYearError("Yıl boş olamaz");
            error = true;
        } else {
            setYearError(null);
        }
        if (page == null) {
            setPageError("Sayfa sayısı boş olamaz");
            error = true;
        } else {
            setPageError(null);
        }
        if (name == null) {
            setNameError("Kitap ismi boş olamaz");
            error = true;
        } else {
            setNameError(null);
        }
        if (about == null) {
            setAboutError("Kitap hakkında boş olamaz");
            error = true;
        } else {
            setAboutError(null);
        }
        if (storeURL == null) {
            setStoreURLError("Satın alma linki boş olamaz");
            error = true;
        } else {
            setStoreURLError(null);
        }
        if (imageUrl == null) {
            setImageUrlError("Kapak görseli boş olamaz");
            error = true;
        } else {
            setImageUrlError(null);
        }


        if (error) {
            return
        }

        event.preventDefault();
        setSending(true);
        const body = {
            "authorIds": [authorIds],
            name,
            about,
            imageUrl,
            page,
            publisherId,
            storeURL,
            year
        }
        try {
            let response = await saveBook(body);
            if (response.status === 201) {
                setmsg("Kitap Kaydedildi")
                setSuccess(true);
                await new Promise(r => setTimeout(r, 1500));
                window.location.reload();
            } else {
                setmsg(response.response.data?.message)
                setSuccess(false);
                setSending(false);
                setNameError(response.response.data?.validationErrors?.name);
                setAboutError(response.response.data?.validationErrors?.about);
                setImageUrlError(response.response.data?.validationErrors?.imageUrl);
                setPageError(response.response.data?.validationErrors?.page);
                setPublisherIdError(response.response.data?.validationErrors?.publisherId);
                setYearError(response.response.data?.validationErrors?.year);
                setAuthorIdsError(response.response.data?.validationErrors?.authorIds);
                setStoreURLError(response.response.data?.validationErrors?.storeURL);
            }
        } catch (error) {
            setmsg(error.response.data.validationErrors)
            setSuccess(false);
            setSending(false);
            setNameError(error.response.data.validationErrors.name);
            setAboutError(error.response.data.validationErrors.about);
            setImageUrlError(error.response.data.validationErrors.imageUrl);
            setPageError(error.response.data.validationErrors.page);
            setPublisherIdError(error.response.data.validationErrors.publisherId);
            setYearError(error.response.data.validationErrors.year);
            setAuthorIdsError(error.response.data.validationErrors.authorIds);
            setStoreURLError(error.response.data?.validationErrors?.storeURL);
        }
        setSending(false);
    };

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {success ? <Alert className="popup-fixed alert_sign" variant="success" timeout="3000" title={msg}/> : ""}
                    <div className="sign_info">
                        <div className="row">
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Kitap Ekle</h2>
                                <form action="#" className="">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Kitap İsmi*</label>
                                        <input type="text" name="name" onChange={(e) => {
                                            setName(e.target.value)
                                            setNameError(null)
                                        }}
                                               required/>
                                        <span style={{color: "red"}}> {nameError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Kitap Hakkında*</label>
                                        <textarea name="abou" onChange={(e) => {
                                            setAbout(e.target.value)
                                            setAboutError(null);
                                        }}/>
                                        <span style={{color: "red"}}> {aboutError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Kapak Resmi*</label>
                                        <small>
                                            <a href="/gorsel-yukle" target="_blank"><b> (görsel yükle)</b></a>
                                        </small>
                                        <input type="text" name="imageUrl"
                                               onChange={(e) => {
                                                   setImageUrl(e.target.value)
                                                   setImageUrlError(null);
                                               }}/>
                                        <span style={{color: "red"}}> {imageUrlError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Sayfa Sayısı*</label>
                                        <input type="text" name="page" onChange={(e) => {
                                            setPage(e.target.value)
                                            setPageError(null);
                                        }}
                                               required/>
                                        <span style={{color: "red"}}> {pageError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Yıl*</label>
                                        <input type="text" name="year" onChange={(e) => {
                                            setYear(e.target.value)
                                            setYearError(null);
                                        }}
                                               required/>
                                        <span style={{color: "red"}}> {yearError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Satın alma linki*</label>
                                        <input type="text" name="storeURL" onChange={(e) => {
                                            setStoreURL(e.target.value)
                                            setStoreURLError(null);
                                        }}
                                               required/>
                                        <span style={{color: "red"}}> {storeURLError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Yazar*
                                            <small>
                                                <a href="/addAuthor" target="_blank"><b>yazar ekle</b></a>
                                                (yazar ekledikten sonra sayfayı yenilemeye gerek yoktur)
                                            </small>
                                        </label>
                                        <AsyncSelect
                                            required
                                            placeholder="Yazarı ismiyle arayabilirsiniz"
                                            cacheOptions
                                            defaultOptions
                                            isLoading={isLoadingAsync}
                                            getOptionLabel={(e) => e.name}
                                            getOptionValue={(e) => e.id}
                                            loadOptions={loadAuthorOptions}
                                            onChange={(value) => {
                                                setAuthorIds(value.id)
                                                setAuthorIdsError(null);
                                            }}
                                            noOptionsMessage={({ inputValue }) => (
                                                <span>{inputValue} için sonuç yok</span>
                                            )}
                                        />
                                        <span style={{color: "red"}}> {authorIdsError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Yayın Evi*
                                            <small>
                                                <a href="/addPublisher" target="_blank"><b>yayın evi ekle</b></a>
                                                (yayın evi ekledikten sonra sayfayı yenilemeye gerek yoktur)
                                            </small></label>
                                        <AsyncSelect
                                            required
                                            cacheOptions
                                            placeholder="Yayınevini ismiyle arayabilirsiniz"
                                            defaultOptions
                                            isLoading={isLoadingAsync}
                                            getOptionLabel={(e) => e.name}
                                            getOptionValue={(e) => e.id}
                                            loadOptions={loadPublisherOptions}
                                            onChange={(value) => {
                                                setPublisherId(value.id)
                                                setPublisherIdError(null);
                                            }}
                                            noOptionsMessage={({ inputValue }) => (
                                                <span>{inputValue} için sonuç yok</span>
                                            )}
                                        />
                                        <span style={{color: "red"}}> {publisherIdError} </span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {!sending ? <button disabled={sending} type="button" onClick={onSubmit}
                                                            className="btn_three">Kaydet</button> : <Spinner/>}
                                    </div>
                                    <br/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default BookSaveForm;
