import React, {useEffect, useState} from "react";
import 'react-data-table-component-extensions/dist/index.css';
import 'reactjs-popup/dist/index.css';
import {getVersions} from "./api/apiCalls";
import DataTable from "react-data-table-component";
import {Button, Spinner} from '@patternfly/react-core';
import {useHistory} from "react-router-dom";

const VersionListForm = (props) => {
    const [key] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'Market Version',
            selector: row => row.marketVersion,
        },
        {
            name: 'Minimum Version',
            selector: row => row.minVersion,
        },
        {
            name: 'Environment',
            selector: row => row.environment,
        },
        {
            name: 'Güncelle',
            selector: row => <Button className="btn_get btn_hover" style={{minWidth: "80px"}} onClick={() => {
                history.push(`/versiyon/${row.environment}`)
            }}>Güncelle</Button>,
        }
    ]

    useEffect(() => {
        setLoading(true);
        getVersions().then(r => {
            setData(r.data.data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [key]);

    return (
        <section className="sign_in_area bg_color sec_pad">
            <div className="container">
                <div className="sign_info">
                    <div className="row">
                        <div className="container">
                            <DataTable
                                title="Versiyonlar"
                                highlightOnHover={true}
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                striped
                                progressComponent={<Spinner/>}
                                noDataComponent="Sonuç Bulunamadı"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default VersionListForm;