import {Spinner} from '@patternfly/react-core';
import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {useSelector} from 'react-redux';
import {getAdminUsers, getDashboard} from './api/apiCalls';

const WelcomePage = () => {
    const {isLoggedIn} = useSelector(store => ({
        isLoggedIn: store.isLoggedIn
    }));

    const [data, setData] = useState([]);
    const [dashboardData, setDashboardData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'Ad',
            selector: row => row.name,
        },
        {
            name: 'Soyad',
            selector: row => row.surname,
        },
        {
            name: 'Mail',
            selector: row => row.mail,
        },
    ];

    const columnsDashboard = [
        {
            name: 'Kullanıcı Sayısı',
            selector: row => row.userCount,
        },
        {
            name: 'Yeni Kullanıcı (son 24 saat)',
            selector: row => row.newUserCount,
        },
        {
            name: 'Kitap Sayısı',
            selector: row => row.bookCount,
        },
        {
            name: 'Alıntı Sayısı',
            selector: row => row.quoteCount,
        },
        {
            name: 'Random Alıntı Sayısı',
            selector: row => row.randomQuoteCount,
        },
        {
            name: 'Oy Sayısı',
            selector: row => row.voteCount,
        },
    ];

    const getAdmins = async () => {
        setLoading(true);
        const response = await getAdminUsers();
        setData(response.data.data);
        setLoading(false);

        setLoading2(true);
        const response2 = await getDashboard();
        let arr = [];
        arr.push(response2.data.data)
        setDashboardData(arr);
        setLoading2(false);
    }

    useEffect(() => {
        getAdmins();
    }, [0]);

    return (
        <div>
            <section className="support_home_area">
                <div className="banner_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h2 className="f_p f_size_40 l_height60 wow fadeInUp"
                                    data-wow-delay="0.3s">Qupic <span className="f_700">Yönetim</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='container'>
                <div className='row'>
                    <DataTable
                        title="Yöneticiler"
                        highlightOnHover={true}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        striped
                        progressComponent={<Spinner/>}
                        noDataComponent="Sonuç Bulunamadı"
                    />
                </div>
            </div>
            <br/>
            <br/>
            <div className='container'>
                <div className='row'>
                    <DataTable
                        title="İstatistik"
                        highlightOnHover={true}
                        columns={columnsDashboard}
                        data={dashboardData}
                        progressPending={loading2}
                        striped
                        progressComponent={<Spinner/>}
                        noDataComponent="Sonuç Bulunamadı"
                    />
                </div>
                <br/>
            </div>
        </div>
    )
}
export default WelcomePage;