import {Alert, Spinner} from "@patternfly/react-core";
import React, {useState} from "react";
import {uploadImage} from "./api/apiCalls";

const ImageSaveForm = () => {
    const [msg, setMessage] = useState();
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [responseAws, setResponseAws] = useState("");
    let formData = new FormData();

    const onFileChange = (e) => {
        formData.append("file", e.target.files[0]);
    };

    const onSubmit = async event => {
        event.preventDefault();
        if (!formData.has("file")) {
            setMessage("Dosya seçiniz!")
        } else {
            setSending(true);
            try {
                const data = await uploadImage(formData);
                setMessage("Görsel yüklendi")
                setResponseAws(data.data.data)
                setSuccess(true);
            } catch (error) {
                setMessage("<b>Hata</b>")
                setSuccess(false);
                setSending(false);
            }
            setSending(false);
        }
    };

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {success ?
                        <div className="sign_info">
                            <p style={{fontSize: "24px"}}>Başarıyla Yüklendi</p>
                            <p style={{fontSize: "18px", color: "red"}}>Görsel URL'ini kopyalayınız!</p>
                            <br/>
                            Görsel URL:
                            <div className="form-group text_box">
                                <input className="form-group text_box" type="text" value={responseAws} disabled></input>
                                <img src={responseAws} alt=""/>
                            </div>
                        </div>
                        :
                        <div className="sign_info">
                            <div className="row">
                                <div className="container">
                                    {success ? {responseAws} : ""}
                                    <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Görsel Yükle</h2>
                                    <form action="#" className="">
                                        <div className="form-group text_box">
                                            <label className="f_p text_c f_400">
                                                <b>2MB</b> boyutunu geçmeyen <b>.jpg</b> dosyası yüklenebilir.<br/>
                                                <i>Dosyalar Google Drive'a yükleniyor.</i>
                                            </label>
                                            <br/>
                                            <br/>
                                            <input
                                                type="file"
                                                id="file_upload"
                                                onChange={(e) => onFileChange(e)}
                                                accept=".jpg"
                                            />
                                        </div>
                                        <div>
                                            {!sending ?
                                                <button disabled={sending}
                                                        type="button"
                                                        onClick={onSubmit}
                                                        className="btn_three">Yükle</button>
                                                : <Spinner/>}
                                        </div>
                                        <br/>
                                        {msg ? <h2 style={{color: "red"}}>{msg}</h2> : ""}
                                        <br/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
        </>
    )
}
export default ImageSaveForm;