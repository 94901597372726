import React, {useEffect, useState} from "react";
import 'react-data-table-component-extensions/dist/index.css';
import 'reactjs-popup/dist/index.css';
import {getVersions, updateVersion} from "./api/apiCalls";
import {useHistory, useParams} from "react-router-dom";
import {Alert, Spinner} from "@patternfly/react-core";

const VersionUpdateForm = (props) => {
    const {id} = useParams();
    const history = useHistory();
    const [key] = useState(0);

    const [marketVersion, setMarketVersion] = useState();
    const [minVersion, setMinVersion] = useState();
    const [environment, setEnvironment] = useState();
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const [msg, setMsg] = useState("");

    const [marketVersionError, setMarketVersionError] = useState();
    const [minVersionError, setMinVersionError] = useState();
    const onSubmit = async event => {
        event.preventDefault();
        setSending(true);
        setSuccess(false);
        setFailed(false);
        let versionId = -1;
        if (environment === "IOS") {
            versionId = 1;
        } else if (environment === "ANDROID") {
            versionId = 0;
        }
        const params = `marketVersion=${marketVersion}&minVersion=${minVersion}&environment=${versionId.toString()}`;

        await updateVersion(params)
            .then(response => {
                if (response.status === 200) {
                    setSuccess(true);
                    setMsg("Sürüm Güncellendi!")
                    setSending(false);
                    new Promise(r => setTimeout(r, 1000))
                        .then(r => window.location.reload());
                } else {
                    setSending(false);
                    setFailed(true);
                    setMarketVersionError(response.response?.data?.validationErrors?.marketVersion);
                    setMinVersionError(response.response?.data?.validationErrors?.minVersion);
                    setMsg(response.response?.data?.message)
                }
            })
            .catch(error => {
                console.log(error)
                setSending(false);
                setFailed(true);
                setMsg("API bağlantı hatası! // console log")
            })
    };

    useEffect(() => {
        setLoading(true);
        getVersions().then(r => {
            const version = r?.data?.data?.filter(version => version.environment === id)[0];
            setMarketVersion(version?.marketVersion);
            setMinVersion(version?.minVersion);
            setEnvironment(version?.environment);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
    }, [key]);

    return (
        <section className="sign_in_area bg_color sec_pad">
            <div className="container">
                <div className="sign_info">
                    <div className="row">
                        {success ? <Alert className="alert_sign" variant="success" timeout="3000" title={msg}/> : ""}
                        {failed ? <Alert className="alert_sign" variant="danger" timeout="10000" title={msg}/> : ""}

                        {loading ? <Spinner/> :
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_20">Sürüm Güncelle</h2>

                                <div className="sign_info_content mb_40">
                                    <h4 className="f_p f_400 f_size_16 t_color3">
                                        Sürümler 3 sayı ve aralarında bir nokta olacak şekilde oluşturulmalıdır.
                                        Örneğin: <b>2.9.1</b>
                                    </h4>
                                </div>

                                <form action="#" className="login-form sign-in-form">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Minimum Sürüm</label>
                                        <input
                                            value={minVersion}
                                            name="minVersion"
                                            onChange={(e) => setMinVersion(e.target.value)}
                                            type="text"/>
                                        <span style={{color: "red"}}> {minVersionError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Market Sürüm</label>
                                        <input
                                            value={marketVersion}
                                            name="marketVersion"
                                            onChange={(e) => setMarketVersion(e.target.value)}
                                            type="text"/>
                                        <span style={{color: "red"}}> {marketVersionError} </span>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Ortam</label>
                                        <input
                                            style={{background: "lightgray"}}
                                            value={environment}
                                            name="environment"
                                            disabled={true}
                                            aria-disabled={true}
                                            type="text"/>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {!sending ?
                                            <button disabled={sending}
                                                    type="submit"
                                                    onClick={onSubmit}
                                                    className="btn_three">Güncelle</button>
                                            : <Spinner/>}
                                    </div>
                                    <br/>
                                    <span>* zorunlu alanlar</span>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </section>
    )
}
export default VersionUpdateForm;