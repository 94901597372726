import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import PublisherSaveForm from "../components/PublisherSaveForm";

const PublisherSave = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" hbtnClass="d-"/>
            <PublisherSaveForm/>
        </div>
    )
}
export default PublisherSave;