import {Alert, Spinner} from "@patternfly/react-core";
import React, {useEffect, useState} from "react";
import {useParams} from 'react-router';
import {useHistory} from "react-router-dom";
import {getOneAuthor, updateAuthor} from "./api/apiCalls";

const AuthorUpdateForm = () => {
    const { id } = useParams();

    const [error, setError] = useState(false);
    const history = useHistory();

    const [nameError, setNameError] = useState("");
    const [AboutError, setAboutError] = useState("");
    const [imageUrlError, setImageUrlError] = useState("");

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const [author, setAuthor] = useState({});
    const [name, setName] = useState("");
    const [about, setAbout] = useState("");
    const [imageUrl, setImageUrl] = useState("");


    const onSubmit = async event => {
        event.preventDefault();
        setSending(true);
        const body = {
            "name": name,
            "about": about,
            "imageUrl": imageUrl
        }
        try {
            await updateAuthor(id, body);
            setSuccess(true);
            await new Promise(r => setTimeout(r, 1000));
            window.location.reload();
        }
        catch (error) {
            setSending(false);
            setNameError(error.response.data.validationErrors.name);
            setAboutError(error.response.data.validationErrors.about);
        }
        setSending(false);
    };

    const getAuthor = async () => {
        const resp = await getOneAuthor(id);
        setAuthor(resp.data.data);
        setName(resp.data.data.name)
        setAbout(resp.data.data.about)
        setImageUrl(resp.data.data.imageUrl)
    }

    // ekran ilk yuklenirken olacaklar
    useEffect(() => {
        try {
            getAuthor();
        }
        catch (error) {
            setSending(false);
            setError(error.response.data.message);
        }
    }, [0]);

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    <div className="sign_info">
                        {success ? <Alert className="popup-fixed alert_sign" variant="success" timeout="3000" title="Yazar Güncellendi" /> : ""}
                        <div className="row">
                            <div className="container">
                                <div className="login_info">
                                    <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Yazar Güncelle</h2>
                                    <form action="#" className="login-form sign-in-form">
                                        <div className="form-group text_box">
                                            <label className="f_p text_c f_400">Ad *</label>
                                            <input
                                                defaultValue={author.name}
                                                name="name"
                                                onChange={(e) => setName(e.target.value)}
                                                type="text" />
                                            <span style={{ color: "red" }}> {nameError} </span>
                                        </div>
                                        <div className="form-group text_box">
                                            <label className="f_p text_c f_400">Hakkında *</label>
                                            <textarea
                                                defaultValue={author.about}
                                                onChange={(e) => setAbout(e.target.value)}
                                                name="about" />
                                            <span style={{ color: "red" }}> {AboutError} </span>
                                        </div>
                                        <div className="form-group text_box">
                                            <label className="f_p text_c f_400">Yazar Fotoğraf Linki</label>
                                            <input
                                                defaultValue={author.imageUrl}
                                                name="email"
                                                onChange={(e) => setImageUrl(e.target.value)}
                                                type="email" />
                                            <span style={{ color: "red" }}> {imageUrlError} </span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            {!sending ? <button disabled={sending} type="submit" onClick={onSubmit} className="btn_three">Güncelle</button> : <Spinner />}
                                        </div>
                                        <br />
                                        <span>* zorunlu alanlar</span>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
    )
}
export default AuthorUpdateForm;