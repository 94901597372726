import React from 'react';
import {Button, EmptyState, EmptyStateBody, EmptyStateIcon, Title} from '@patternfly/react-core';
import WarningIcon from '@patternfly/react-icons/dist/esm/icons/bacon-icon';
import {useHistory} from "react-router-dom";

export const Denied = () => {
    const history = useHistory();

    const goTo = () => {
        history.push('/SingIn');
    }

    return (<section className="sign_in_area bg_color sec_pad">
        <div className="container">
            <EmptyState>
                <EmptyStateIcon icon={WarningIcon}/>
                <Title headingLevel="h4" size="lg">
                    Lütfen Giriş Yapınız
                </Title>
                <EmptyStateBody>
                    Lütfen Giriş Yapınız
                </EmptyStateBody>
                <Button onClick={goTo} variant="primary">Giriş Yap</Button>
            </EmptyState>
        </div>
    </section>)
}