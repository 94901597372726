import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";

const NotFound = (props) => {
    const history = useHistory();

    const {isLoggedIn} = useSelector(store => ({
        isLoggedIn: store.isLoggedIn
    }));

    useEffect(() => {
        if (!isLoggedIn) {
            history.push('/SignIn');
            window.location.replace("/SignIn");
        }
        history.push('/');
    });

    return (
        <div className="body_wrapper"></div>
    )
}
export default NotFound;
