import React, {useEffect, useState} from "react";
import 'react-data-table-component-extensions/dist/index.css';
import 'reactjs-popup/dist/index.css';
import {approveComment, deleteComment, disapproveComment, getComments} from "./api/apiCalls";
import DataTable from "react-data-table-component";
import {Alert, Button, Select, SelectOption, Spinner} from '@patternfly/react-core';
import {confirmAlert} from "react-confirm-alert";

const CommentListForm = () => {
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [msg, setMsg] = useState("");

    const [bookName, setBookName] = useState("");
    const [mail, setMail] = useState("");
    const [published, setPublished] = useState("");
    const [optOpen, setOptOpen] = useState(false);

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const selectOptions = [
        <SelectOption key={0} value="2"> - </SelectOption>,
        <SelectOption key={1} value="1"> Onaylı </SelectOption>,
        <SelectOption key={2} value="0"> Onay Bekleyen </SelectOption>,
    ];

    const columns = [
        {
            name: 'ID',
            selector: row => row?.id,
        },
        {
            name: 'Yorum',
            selector: row => row?.comment,
        },
        {
            name: 'Kitap İsmi / Kitap ID',
            selector: row => <span> {row?.bookName} / ({row?.bookId}) </span>,
        },
        {
            name: 'Kullanıcı',
            selector: row => row?.user,
        },
        {
            name: 'Kullanıcı E-Maili',
            selector: row => row?.userMail,
        },
        {
            name: '' +
                'Yorumu Onayla',
            selector: row => row?.active === false ?
                <Button
                    className="btn_get btn_hover"
                    onClick={() => {
                        approveComment(row?.id)
                            .then(r => {
                                setSuccess(false);
                                filter({})
                                    .then(r => setMsg("Yorum Onaylandı"))
                                    .then(r => setSuccess(true))
                            });
                    }}
                    style={{minWidth: "80px"}}>Onayla</Button> :
                <Button
                    className="btn_get btn_hover pf-m-danger"
                    onClick={() => {
                        disapproveComment(row?.id)
                            .then(r => {
                                setSuccess(false);
                                filter({})
                                    .then(r => setMsg("Yorum Onayı Kaldırıldı"))
                                    .then(r => setSuccess(true))
                            });
                    }}
                    style={{minWidth: "80px"}}>Onay Kaldır</Button>,
        },
        {
            name: 'Yorumu Sil',
            selector: row =>
                <Button
                    className="btn_get btn_hover pf-m-danger"
                    onClick={() => {
                        deleteCommentConfirmation(row.id)
                    }}
                    style={{minWidth: "80px"}}>Sil</Button>,
        }
    ]

    const deleteCommentConfirmation = (id) => {
        setSuccess(false);
        confirmAlert({
            title: 'Yorum Sil',
            message: ``,
            buttons: [
                {
                    label: 'Evet',
                    onClick: async () => {
                        deleteComment(id)
                            .then(r => {
                                filter({})
                                    .then(r => setMsg("Yorum Silindi"))
                                    .then(r => setSuccess(true))
                            });
                    }
                },
                {
                    label: 'Hayır',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    const handlePageChange = async (pageNew) => {
        const pageA = pageNew <= 0 ? 0 : pageNew - 1;
        setCurrentPage(pageA)
        setLoading(true);
        const queryParams = new URLSearchParams({
            mail: mail,
            book: bookName,
            approved: published,
            page: pageA,
            size: perPage
        }).toString();
        const response = await getComments(`?${queryParams}`);
        setData(response?.data?.data?.content);
        setTotalRows(response?.data?.data?.totalElements);
        setLoading(false);
    };


    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        page = page <= 0 ? 0 : page - 1;
        setCurrentPage(page)
        setPerPage(newPerPage);

        const queryParams = new URLSearchParams({
            mail: mail,
            book: bookName,
            approved: published,
            page: page,
            size: newPerPage
        }).toString();
        const response = await getComments(`?${queryParams}`);

        setData(response.data.data.content);
        setLoading(false);
    };

    const filter = async ({mailA, publishedA, bookNameA}) => {
        setLoading(true);
        const queryParams = new URLSearchParams({
            mail: mailA ? mailA : mailA === '' ? mailA : mail,
            book: bookNameA ? bookNameA : bookNameA === '' ? bookNameA : bookName,
            approved: publishedA ? publishedA : publishedA === '' ? publishedA : published
        }).toString();
        const response = await getComments(`?${queryParams}`);
        setData(response?.data?.data?.content);
        setTotalRows(response?.data?.data?.totalElements);
        setLoading(false);
    };

    const reset = async () => {
        setMail("")
        setBookName("")
        setPublished("")
        filter({})
    };


    useEffect(() => {
        filter({})
    }, [0]);

    return (
        <section className="sign_in_area bg_color sec_pad">
            <div className="container">
                <div className="sign_info">
                    {<Spinner style={{display: "none"}}/>}
                    {success ?
                        <Alert className="popup-fixed alert_sign" variant="success" timeout="3000" title={msg}/> : ""}
                    <div className="container">
                        <div className="row" style={{paddingBottom: "10px"}}>
                            <div className="col-lg-3 nopadding">
                                <input
                                    placeholder="Kitap İsmi"
                                    style={{width: "100%", height: "100%"}}
                                    onChange={(e) => {
                                        const name = e.target.value.trim() || '';
                                        setBookName(name)
                                        filter({
                                            bookNameA: name
                                        })
                                    }}
                                    onCut={(e) => e.preventDefault()}
                                /></div>
                            <div className="col-lg-3 nopadding">
                                <input
                                    placeholder="Kullanıcı Mail"
                                    style={{width: "100%", height: "100%"}}
                                    onChange={(e) => {
                                        const name = e.target.value.trim() || '';
                                        setMail(name)
                                        filter({
                                            mailA: name
                                        })
                                    }}
                                    onCut={(e) => e.preventDefault()}
                                /></div>
                            <div className="col-lg-3 nopadding">
                                <Select
                                    placeholderText="Yayım Durumu"
                                    selections={published}
                                    isOpen={optOpen}
                                    onToggle={(isOpen) => setOptOpen(isOpen)}
                                    onSelect={(_, value) => {
                                        setPublished(value.toString());
                                        setOptOpen(false);
                                        filter({
                                            publishedA: value.toString()
                                        })
                                    }}
                                    variant="typeahead"
                                >
                                    {selectOptions}
                                </Select>
                            </div>
                            <div className="col-lg-3" style={{display: "flex"}}>
                                <Button component="a" className="btns filter-item"
                                        onClick={e => {
                                            filter({})
                                        }} target="_blank"
                                        style={{background: "#06c", color: "white"}}
                                        variant="tertiary">
                                    Filtrele
                                </Button>

                                <Button component="a" className="btns filter-item"
                                        onClick={e => {
                                            reset()
                                        }} target="_blank"
                                        style={{background: "#dc3545", color: "white"}}
                                        variant="tertiary">
                                    Sıfırla
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="container">
                            <DataTable
                                title="Yorumlar"
                                highlightOnHover={true}
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                striped
                                pagination
                                paginationServer
                                noDataComponent="Sonuç Bulunamadı"
                                paginationTotalRows={totalRows}
                                data-tag="allowRowEvents"
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                progressComponent={<Spinner/>}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
export default CommentListForm;