import React, {useEffect} from "react";
import {useSelector} from 'react-redux';
import {BrowserRouter as Router, Switch} from "react-router-dom";
import NotFound from "./Pages/404";
import AdminSignIn from "./Pages/AdminSingIn";
import AuthorList from "./Pages/AuthorList";
import AuthorUpdate from "./Pages/AuthorUpdate";
import BookList from "./Pages/BookList";
import BookSave from "./Pages/BookSave";
import LandingPage from "./Pages/LandingPage";
import QuoteList from "./Pages/QuoteList";
import QuoteSave from "./Pages/QuoteSave";
import Account from "./Pages/AccountDetails";
import UserList from "./Pages/UserList";
import ScrollToTopRoute from "./ScrollToTopRoute";
import RandomQuoteList from "./Pages/RandomQuoteList";
import RandomQuoteSave from "./Pages/RandomQuoteSave";
import AuthorSave from "./Pages/AuthorSave";
import ImageSave from "./Pages/ImageSave";
import ImageList from "./Pages/ImageList";
import PublisherSave from "./Pages/PublisherSave";
import PublisherList from "./Pages/PublisherList";
import BookUpdate from "./Pages/BookUpdate";
import CacheEvict from "./Pages/CacheEvict";
import VersionList from "./Pages/VersionList";
import VersionUpdate from "./Pages/VersionUpdate";
import CommentList from "./Pages/CommentList";
import AppHomePage from "./Pages/AppHomePage";
import AppHomePageEdit from "./Pages/AppHomePageEdit";
import CategoryList from "./Pages/CategoryList";
import CategorySave from "./Pages/CategorySave";

const App = (props) => {
    const {isLoggedIn} = useSelector(store => ({
        isLoggedIn: store.isLoggedIn
    }));

    useEffect(() => {
        props.hideLoader();
    });

    useEffect(() => {
        document.title = `QUPIC Panel | version: ${process.env.REACT_APP_VERSION}`;
    })

    return (
        <Router>
            <Switch>
                {isLoggedIn && <ScrollToTopRoute exact={true} path={"/"} component={LandingPage}/>}

                {isLoggedIn && <ScrollToTopRoute path="/app-ana-ekran" component={AppHomePage}/>}
                {isLoggedIn && <ScrollToTopRoute path="/app-ana-ekran-duzenle" component={AppHomePageEdit}/>}

                {isLoggedIn && <ScrollToTopRoute path="/alintilar" component={QuoteList}/>}
                {isLoggedIn && <ScrollToTopRoute path="/alinti-ekle" component={QuoteSave}/>}
                {isLoggedIn && <ScrollToTopRoute path="/rastgele-alintilar" component={RandomQuoteList}/>}
                {isLoggedIn && <ScrollToTopRoute path="/rastgele-alinti-ekle" component={RandomQuoteSave}/>}

                {isLoggedIn && <ScrollToTopRoute path="/kitaplar" component={BookList}/>}
                {isLoggedIn && <ScrollToTopRoute path="/kitap-ekle" component={BookSave}/>}
                {isLoggedIn && <ScrollToTopRoute path="/book/:id" component={BookUpdate}/>}
                {isLoggedIn && <ScrollToTopRoute path="/kategoriler" component={CategoryList}/>}
                {isLoggedIn && <ScrollToTopRoute path="/kategori-ekle" component={CategorySave}/>}

                {isLoggedIn && <ScrollToTopRoute path="/yazarlar" component={AuthorList}/>}
                {isLoggedIn && <ScrollToTopRoute path="/yazar-ekle" component={AuthorSave}/>}
                {isLoggedIn && <ScrollToTopRoute path="/yazar/:id" component={AuthorUpdate}/>}

                {isLoggedIn && <ScrollToTopRoute path="/kullanicilar" component={UserList}/>}

                {isLoggedIn && <ScrollToTopRoute path="/yorumlar" component={CommentList}/>}

                {isLoggedIn && <ScrollToTopRoute path="/cache-sil" component={CacheEvict}/>}
                {isLoggedIn && <ScrollToTopRoute path="/versiyonlar" component={VersionList}/>}
                {isLoggedIn && <ScrollToTopRoute path="/versiyon/:id" component={VersionUpdate}/>}
                {isLoggedIn && <ScrollToTopRoute path="/gorsel-yukle" component={ImageSave}/>}
                {isLoggedIn && <ScrollToTopRoute path="/gorseller" component={ImageList}/>}

                {isLoggedIn && <ScrollToTopRoute path="/yayinevleri" component={PublisherList}/>}
                {isLoggedIn && <ScrollToTopRoute path="/yayinevi-ekle" component={PublisherSave}/>}

                {isLoggedIn && <ScrollToTopRoute path="/hesabim" component={Account}/>}


                {!isLoggedIn && <ScrollToTopRoute path="/SignIn" component={AdminSignIn}/>}
                <ScrollToTopRoute component={NotFound}/>
            </Switch>
        </Router>
    );
}

export default App;
