import {Button, Spinner} from '@patternfly/react-core';
import React, {useEffect, useState} from "react";
import DataTable, {Alignment} from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import {useHistory} from "react-router-dom";
import {getAuthorList} from "./api/apiCalls";

const AuthorListForm = (props) => {

    const history = useHistory();
    const [name, setName] = useState("");
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [params, setParams] = useState();

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'Ad',
            selector: row => row.name,
        },
        {
            name: 'Hakkında',
            selector: row => <div><span>{
                row.about ? row.about.length > 150 ? row.about.slice(0, 149) + "....." : row.about : "-"
            }</span></div>,
        },
        {
            name: 'Fotoğraf',
            selector: row => row.imageUrl ?
                <a href={row.imageUrl} target="_blank" rel="noopener noreferrer">Görsel</a> : "-",
        },
        {
            name: 'Güncelle',
            selector: row => <Button className="btn_get btn_hover" style={{minWidth: "80px"}} onClick={() => {
                history.push(`/yazar/${row.id}`)
            }}>Güncelle</Button>,
        },
    ];

    const fetchData = async page => {
        setLoading(true);

        if (params !== undefined) {
            const response = await getAuthorList("?" + params + `&page=${page}&size=${perPage}`);
            setData(response.data.data.content);
            setTotalRows(response.data.data.totalElements);
            setLoading(false);
        } else {
            const response = await getAuthorList(`?page=${page}&size=${perPage}&delay=1`);
            setData(response.data.data.content);
            setTotalRows(response.data.data.totalElements);
            setLoading(false);
        }
    };

    const handlePageChange = page => {

        fetchData(page - 1);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        page = page - 1
        const response = await getAuthorList(`?page=${page}&size=${newPerPage}`);

        setData(response.data.data.content);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const filter = async ({nameA}) => {
        setLoading(true);
        const params = new URLSearchParams({
            name: nameA ? nameA : nameA === '' ? '' : name,
        }).toString();
        setParams(params);
        const data = await getAuthorList("?" + params);
        setData(data.data.data.content);
        setTotalRows(data.data.data.totalElements);
        setLoading(false);
    }


    const reset = async () => {
        setName("")
        setLoading(true);
        const params = new URLSearchParams({}).toString();
        setParams(params);
        const data = await getAuthorList("");
        setData(data.data.data.content);
        setTotalRows(data.data.data.totalElements);
        setLoading(false);
    }

    useEffect(() => {
        fetchData(0);
    }, [0]);


    function getSubHeaderComponent() {
        return <Button onClick={e => history.push('/yazar-ekle')}>Yazar ekle</Button>;
    }

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {loader ? <div className="sign_info">
                        <div className="row" style={{paddingBottom: "10px"}}>
                            <div className="col-lg-3 nopadding">
                                <input
                                    placeholder="Ad"
                                    style={{width: "100%", height: "100%"}}
                                    value={name}
                                    onChange={e => {
                                        const value = e.target.value.trim() || '';
                                        setName(value);
                                        filter({nameA: value})
                                    }}
                                />
                            </div>
                            <div className="col-lg-3" style={{display: "flex"}}>
                                <Button component="a" className="btns filter-item"
                                        onClick={event => {
                                            filter({nameA: ''})
                                        }}
                                        target="_blank"
                                        style={{background: "#06c", color: "white"}}
                                        variant="tertiary">
                                    Filtrele
                                </Button>

                                <Button component="a" className="btns filter-item" target="_blank"
                                        onClick={event => {
                                            reset()
                                        }}
                                        style={{background: "#dc3545", color: "white"}}
                                        variant="tertiary">
                                    Sıfırla
                                </Button>
                            </div>
                        </div>
                        <DataTable
                            title="Yazarlar"
                            subHeader
                            subHeaderComponent={getSubHeaderComponent()}
                            subHeaderAlign={Alignment.LEFT}
                            highlightOnHover={true}
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            striped
                            pagination
                            data-tag="allowRowEvents"
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            progressComponent={<Spinner/>}
                            noDataComponent="Sonuç Bulunamadı"
                        />
                    </div> : <><br/><br/>
                        <center><Spinner/></center>
                    </>}
                </div>
            </section>
        </>
    )
}
export default AuthorListForm;