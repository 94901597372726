import React, {useEffect, useState} from "react";
import 'react-data-table-component-extensions/dist/index.css';
import 'reactjs-popup/dist/index.css';
import {Alert, Spinner} from '@patternfly/react-core';
import {useHistory} from "react-router-dom";
import AsyncSelect from "react-select/async";
import {getBookList, saveHomepageItemAdmin} from "./api/apiCalls";

const CommentListForm = (props) => {
    const [key] = useState(0);
    const [msg, setMsg] = useState("");
    const [title, setTitle] = useState("");

    const [sending, setSending] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoadingAsync, setIsLoadingAsync] = useState(false);
    const history = useHistory();

    //body
    const [desc, setDesc] = useState("");
    const [bookId, setBookId] = useState(-1);
    const [typeId, setTypeId] = useState(-1);

    const loadOptions = async (text) => {
        return [
            {
                "title": "Önerilen Kitap",
                "type": "RECOMMENDED_BOOK",
                "id": 0
            },
            {
                "title": "Önerilen Kitaplar",
                "type": "RECOMMENDED_LIST",
                "id": 1
            },
            {
                "title": "Editörün Seçtikleri",
                "type": "EDITORS_CHOICE",
                "id": 2
            }
        ];
    };

    const loadBookOptions = async (text) => {
        try {
            const response = await getBookList("?name=" + text);
            return response.data.data.content;
        } catch (error) {
        }
    };

    const onSubmit = (event) => {
        event.preventDefault()
        let isFailed = false;

        setSuccess(false)
        setFail(false)
        setMsg("")
        if (desc.trim() === "") {
            setMsg("Açıklama boş olamaz!")
            setFail(true)
            isFailed = true;
        } else if (!isFailed && bookId === -1) {
            setMsg("Kitap boş olamaz!")
            setFail(true)
            isFailed = true;
        }

        if (isFailed === false) {
            setSending(true);
            const body = {
                "type": typeId,
                "description": desc,
                "bookId": bookId,
            }
            saveHomepageItemAdmin(body)
                .then(r => {
                    if (r.status === 201 || r.status === 200) {
                        setSuccess(true)
                        setFail(false)
                        setMsg("Başarıyla eklendi")
                        new Promise(r => setTimeout(r, 1500))
                            .then(r => window.location.reload());
                    } else {
                        setFail(true)
                        setMsg(r.response?.data?.message)
                    }
                    setSending(false);
                })
                .catch(error => {
                    console.log(error)
                    setMsg("bağlantı hatası")
                    setFail(true)
                    setSending(false);
                })
        }
    }

    useEffect(() => {
    }, [key]);

    return (
        <section className="sign_in_area bg_color sec_pad">
            <div className="container">
                <div className="sign_info">
                    {<Spinner style={{display: "none"}}/>}
                    {success ? <Alert className="popup-fixed alert_sign" variant="success" timeout="3000"
                                      title={msg}/> : ""}
                    {fail ? <Alert className="popup-fixed alert_sign" variant="danger" timeout="3000"
                                   title={msg}/> : ""}
                    <div className="container">
                        <div className="row">
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_colorblack mb_40">Uygulama Ana Ekranını Düzenle</h2>
                                <h4 className="f_p f_300 f_size_14 t_color mb_40">
                                    Düzenlemek istediğiniz ana ekran alanını seçin
                                </h4>
                                <div className="form-group text_box" style={{zIndex: 5}}>
                                    <label className="f_p text_c f_400">Tip Seçimi</label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        isLoading={loading}
                                        getOptionLabel={(e) => e.title}
                                        getOptionValue={(e) => e.id}
                                        loadOptions={loadOptions}
                                        onChange={(value) => {
                                            setTypeId(value.id)
                                            setTitle(value.title)
                                            setShowForm(true)
                                        }}
                                        noOptionsMessage={({inputValue}) => (
                                            <span>{inputValue} için sonuç yok</span>
                                        )}
                                    />
                                </div>

                                {showForm ?
                                    <form action="#" className="" style={{marginTop: "20px"}}>
                                        <hr style={{marginBottom: "20px"}}/>
                                        <h2 className="f_p f_600 f_size_20 t_color2 mb_40">Kaydet: {title}</h2>
                                        {
                                            title === "Önerilen Kitap" ?
                                                <div className="mb_20 seo_title_color">
                                                    Yalnızca <u>bir adet</u> önerilen kitap olabilir.
                                                    Eğer mevcutta <i>önerilen kitap</i> var ise önce onu silmelisin.
                                                </div> : ""
                                        }
                                        <div className="form-group text_box">
                                            <label className="f_p text_c f_400">Kitap*</label>
                                            <AsyncSelect
                                                required
                                                cacheOptions
                                                defaultOptions
                                                placeholder="Kitap ismi ile arama yapılabilir"
                                                isLoading={isLoadingAsync}
                                                getOptionLabel={(e) => e.name}
                                                getOptionValue={(e) => e.id}
                                                loadOptions={loadBookOptions}
                                                onChange={(value) => {
                                                    setBookId(value.id)
                                                }}
                                                noOptionsMessage={({inputValue}) => (
                                                    <span>{inputValue} için sonuç yok</span>
                                                )}
                                            />
                                        </div>
                                        <div className="form-group text_box">
                                            <label className="f_p text_c f_400">Açıklama*</label>
                                            <textarea rows="5"
                                                      onChange={(e) => {
                                                          setDesc(e.target.value)
                                                      }}
                                                      required/>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            {!sending ? <button disabled={sending}
                                                                type="button"
                                                                onClick={onSubmit}
                                                                className="btn_three">Kaydet</button>
                                                : <Spinner/>
                                            }
                                        </div>
                                    </form> : ""
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CommentListForm;