import {Spinner} from '@patternfly/react-core';
import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import {getImages} from "./api/apiCalls";
import 'reactjs-popup/dist/index.css';

const ImageListForm = (props) => {
    const [key] = useState(0);
    const [loader] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            name: 'Görsel',
            selector: row => <img src={row} style={{maxHeight: "100px", padding: "5px"}} alt={""}/>,
            width: "200px"
        },
        {
            name: 'Link',
            selector: row => row,
        },
        {
            name: 'Yeni Sekmede Aç',
            selector: row => <a href={row} target={"_blank"} >URL</a>,
        }
    ];

    const fetchImages = async page => {
        setLoading(true);
        const response = await getImages()
        setData(response.data.data);
        setLoading(false);
    };
    useEffect(() => {
        fetchImages(0).then(r => r);
    }, [key]);

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {loader ?
                        <div className="sign_info">
                            <DataTable
                                title="Görseler"
                                columns={columns}
                                progressComponent={<Spinner/>}
                                noDataComponent="Sonuç Bulunamadı"
                                highlightOnHover={true}
                                data={data}
                                progressPending={loading}
                                data-tag="allowRowEvents"
                                striped
                            />
                        </div> : <><br/><br/>
                            <center><Spinner/></center>
                        </>}
                </div>
            </section>
        </>
    )
}
export default ImageListForm;