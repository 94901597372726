import React from 'react';
import BookSaveForm from '../components/BookSaveForm';
import CustomNavbar from '../components/CustomNavbar';

const BookSave = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" hbtnClass="d-" />
            <BookSaveForm />
        </div>
    )
}
export default BookSave;