import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import ImageSaveForm from "../components/ImageSaveForm";

const ImageSave = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" hbtnClass="d-" />
            <ImageSaveForm />
        </div>
    )
}
export default ImageSave;