import React from 'react';
import HomePageBalon from '../components/WelcomePage';
import CustomNavbar from '../components/CustomNavbar';
import {useSelector} from 'react-redux';
import AdminSignInFrom from '../components/AdminSignIn';

const LandingPage = () => {
    const {isLoggedIn} = useSelector(store => ({
        isLoggedIn: store.isLoggedIn
    }));

    if (!isLoggedIn) {
        return (
            <div className="body_wrapper">
                <AdminSignInFrom/>
            </div>
        )
    } else {
        return (
            <div className="body_wrapper">
                <CustomNavbar mClass="menu_four" hbtnClass="d-"/>
                <HomePageBalon/>
            </div>
        )
    }
}
export default LandingPage;