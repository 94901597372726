import {Button, Spinner} from '@patternfly/react-core';
import React, {useEffect, useState} from "react";
import 'react-data-table-component-extensions/dist/index.css';
import 'reactjs-popup/dist/index.css';
import {listCaches, removeCache} from "./api/apiCalls";
import DataTable from "react-data-table-component";

const CacheEvict = (props) => {
    const [key] = useState(0);
    const [sending, setSending] = useState(false);
    const [result, setResult] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resultText, setResultText] = useState("");
    const [data, setData] = useState();

    const columns = [
        {
            name: 'Cache Name',
            selector: row => row,
        }
    ]

    const fetchCaches = async () => {
        setLoading(true)
        listCaches()
            .then(r => {
                setData(r.data.data)
                setLoading(false)
            })

    }
    useEffect(() => {
        fetchCaches()
    }, [key]);

    return (
        <section className="sign_in_area bg_color sec_pad">
            <div className="container">
                <div className="sign_info">
                    <div className="row">
                        <div className="container">
                            <label className="f_p text_c f_400">
                                Redis üzerinde tutulan bütün cache'leri sil
                            </label>
                            {!sending ?
                                <Button
                                    disabled={sending}
                                    className="btn_get btn_hover pf-m-danger"
                                    style={{marginLeft: "30px", marginRight: "30px"}}
                                    onClick={() => {
                                        setSuccess(false);
                                        setSending(true);
                                        setResult(false);
                                        removeCache()
                                            .then(r => {
                                                setResult(true);
                                                setResultText(r?.data?.data ? "Temizlendi!" : "Hata oldu")
                                                setSuccess(r?.data?.data);
                                                fetchCaches()
                                            })
                                            .then(r => setSending(false));

                                    }}>Cache Temizle</Button> : <Spinner/>
                            }
                            {result ?
                                <div style={{
                                    color: success ? "green" : "red",
                                    display: "inline",
                                    marginLeft: "16px",
                                    fontSize: "20px",
                                    textDecoration: "underline"
                                }}>{resultText}</div> : ""
                            }
                        </div>
                        <hr className="solid" style={{width: "100%", marginTop: "15px", marginBottom: "15px"}}/>
                        <div className="container">
                            <DataTable
                                striped
                                data={data}
                                columns={columns}
                                title="Cache Listesi"
                                highlightOnHover={true}
                                data-tag="allowRowEvents"
                                progressPending={loading}
                                noDataComponent="Sonuç Bulunamadı"
                                progressComponent={<Spinner/>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CacheEvict;