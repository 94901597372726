import React, {useEffect, useState} from 'react';
import BookListForm from '../components/BookListForm';
import CustomNavbar from '../components/CustomNavbar';
import {Denied} from '../components/Denied';

const BookList = () => {
    const [isSap, setIsSap] = useState(false);
    useEffect(() => {
        const data = localStorage.getItem('sap');
        if (data === "true") {
            setIsSap(true);
        }
    }, [isSap]);
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" hbtnClass="d-" />
            {isSap ? <BookListForm /> : <Denied />}
        </div>
    )
}
export default BookList;