import {Alert, Button, Spinner} from '@patternfly/react-core';
import React, {useEffect, useState} from "react";
import DataTable, {Alignment} from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import {deleteCategory, getCategories} from "./api/apiCalls";
import 'reactjs-popup/dist/index.css';
import {useHistory} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";

const CategoryListForm = (props) => {
    const [key] = useState(0);
    const [loader] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const history = useHistory();

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
        },
        {
            name: 'Kategori',
            selector: row => row.category,
        },
        {
            name: 'Aktif',
            selector: row => row.active ? "Evet" : "Hayır",
        },
        {
            name: 'Sil',
            selector: row => <Button className="btn_get btn_hover pf-m-danger"
                                     style={{minWidth: "80px"}}
                                     onClick={() => deleteItemConfirmation(row?.id, row?.category)}
            >Sil</Button>,
        }
    ];

    const deleteItemConfirmation = (id, name) => {
        setSuccess(false)
        confirmAlert({
            title: 'Sileyim mi? Bu işlem geri alınamaz',
            message: `${name} isimli kategoriyi tamamen silmek üzeresin`,
            buttons: [
                {
                    label: 'Evet',
                    onClick: async () => {
                        deleteCategory(id)
                            .then(r => {
                                if (r.status === 200) {
                                    setSuccess(true)
                                    setMsg(`'${name}' isimli kategori silindi`)
                                    fetchCategories(0, 200)
                                } else {
                                    setMsg(r.response?.data?.message ? r.response?.data?.message : "Hata Oldu")
                                }
                            })
                    }
                },
                {
                    label: 'Hayır',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    const fetchCategories = async (page, size) => {
        setLoading(true);
        const response = await getCategories(page, size)
        setData(response.data.data.content);
        setLoading(false);
    };
    useEffect(() => {
        fetchCategories(0, 200).then(r => r);
    }, [key]);

    function getSubHeaderComponent() {
        return <Button onClick={e => history.push('/kategori-ekle')}>Kategori ekle</Button>;
    }

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {loader ?
                        <div className="sign_info">
                            {msg ? <Alert className="popup-fixed alert_sign" variant={success ? "success" : "danger"} timeout="3000"
                                          title={msg}/> : ""}
                            <DataTable
                                title="Kategoriler"
                                subHeader
                                subHeaderComponent={getSubHeaderComponent()}
                                subHeaderAlign={Alignment.LEFT}
                                columns={columns}
                                progressComponent={<Spinner/>}
                                noDataComponent="Sonuç Bulunamadı"
                                highlightOnHover={true}
                                data={data}
                                progressPending={loading}
                                data-tag="allowRowEvents"
                                striped
                            />
                        </div> : <><br/><br/>
                            <center><Spinner/></center>
                        </>}
                </div>
            </section>
        </>
    )
}
export default CategoryListForm;